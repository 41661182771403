export const SEARCH_DATA = {
  stories: [
    {
      access: 'subscription',
      'author-name': 'Feed Migrator',
      'read-time': 5,
      tags: [],
      headline: '122 More Coronavirus Deaths in Massachusetts, 1184 More Test Positive',
      'story-content-id': '62c092f7-9270-4f34-a49e-7b83398511da',
      slug: 'coronavirus/122-more-coronavirus-deaths-in-massachusetts-1184-more-test-positive',
      'last-published-at': 1588770025423,
      subheadline:
        'Gov. Charlie Baker toured a Fall River manufacturing facility that is shifting its focus to produce PPE. Massachusetts&#39; coronavirus death toll rose by&nbsp;...',
      alternative: null,
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url': 'https://media.nbcboston.com/2019/09/Massachusetts-Coronavirus-Map-1-4.jpg?fit=850%2C478'
      },
      'published-at': 1588770025423,
      'item-type': 'story',
      id: '62c092f7-9270-4f34-a49e-7b83398511da',
      'hero-image-s3-key': 'ace/2020-05/1f8da8da-507f-4697-a15f-f49a9372aed6/Massachusetts_Coronavirus_Map_1_4.jpg',
      url:
        'https://ace-web.qtstage.io/coronavirus/122-more-coronavirus-deaths-in-massachusetts-1184-more-test-positive',
      'author-id': 934395,
      'first-published-at': 1588763722342,
      'story-template': 'news-elsewhere',
      'created-at': 1588770031768,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ],
      metadata: {
        'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
        'reference-url':
          'https://www.nbcboston.com/news/coronavirus/122-more-coronavirus-deaths-in-massachusetts-1184-more-test-positive/2119114?utm_source=quintype',
        'imported-card-id': 'fe9c1b68-b014-4aa1-b88d-86999e9f0d2f',
        'card-share': { shareable: false }
      }
    },
    {
      access: 'subscription',
      'author-name': 'Feed Migrator',
      'read-time': 5,
      tags: [],
      headline: '130 More Coronavirus Deaths in Massachusetts, 1952 More Test Positive',
      'story-content-id': 'df37be96-4b6d-456a-a60c-6fe938ebebe8',
      slug: 'coronavirus/130-more-coronavirus-deaths-in-massachusetts-1952-more-test-positive',
      'last-published-at': 1588502711483,
      subheadline:
        'Mass. residents tell NBC 10 Boston they find Baker&#39;s mask order to be a good idea. Massachusetts&#39; coronavirus-related death toll rose by another 130,&nbsp;...',
      alternative: null,
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url': 'https://media.nbcboston.com/2019/09/Massachusetts-Coronavirus-Map-1-4.jpg?fit=850%2C478'
      },
      'published-at': 1588502711483,
      'item-type': 'story',
      id: 'df37be96-4b6d-456a-a60c-6fe938ebebe8',
      'hero-image-s3-key': 'ace/2020-05/cf05320f-37d9-43a8-8a4e-7bd9f4e4a767/Massachusetts_Coronavirus_Map_1_4.jpg',
      url:
        'https://ace-web.qtstage.io/coronavirus/130-more-coronavirus-deaths-in-massachusetts-1952-more-test-positive',
      'author-id': 934395,
      'first-published-at': 1588457701595,
      'story-template': 'news-elsewhere',
      'created-at': 1588502717200,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ],
      metadata: {
        'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
        'reference-url':
          'https://www.nbcboston.com/news/coronavirus/130-more-coronavirus-deaths-in-massachusetts-1952-more-test-positive/2117465?utm_source=quintype',
        'imported-card-id': '13fb4555-bd2c-4054-b89a-2825b10b59d6',
        'card-share': { shareable: false }
      }
    },
    {
      access: 'subscription',
      'author-name': 'Feed Migrator',
      'read-time': 5,
      tags: [],
      headline: 'Coronavirus in Massachusetts: 154 More Deaths, 2106 More People Test Positive',
      'story-content-id': '882481dd-e526-4ff4-96d1-60cc28d86d72',
      slug: 'coronavirus/coronavirus-in-massachusetts-154-more-deaths-2106-more-people-test-positive',
      'last-published-at': 1588419939710,
      subheadline:
        'Baker has been watching the state&#39;s coronavirus statistics carefully as he weighs when to ease the restrictions on movement and businesses in&nbsp;...',
      alternative: null,
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url': 'https://media.nbcboston.com/2019/09/Massachusetts-Coronavirus-Map-1-4.jpg?fit=850%2C478'
      },
      'published-at': 1588419939710,
      'item-type': 'story',
      id: '882481dd-e526-4ff4-96d1-60cc28d86d72',
      'hero-image-s3-key': 'ace/2020-05/46a02445-19c7-4c6f-8341-8de6fe9764d2/Massachusetts_Coronavirus_Map_1_4.jpg',
      url:
        'https://ace-web.qtstage.io/coronavirus/coronavirus-in-massachusetts-154-more-deaths-2106-more-people-test-positive',
      'author-id': 934395,
      'first-published-at': 1588378522810,
      'story-template': 'news-elsewhere',
      'created-at': 1588419944041,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ],
      metadata: {
        'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
        'reference-url':
          'https://www.nbcboston.com/news/coronavirus/coronavirus-in-massachusetts-154-more-deaths-2106-more-people-test-positive/2117143?utm_source=quintype',
        'imported-card-id': '8720d082-20e8-44be-87b1-f84b9ae64a6b',
        'card-share': { shareable: false }
      }
    },
    {
      access: 'subscription',
      'author-name': 'Feed Migrator',
      'read-time': 5,
      tags: [],
      headline: 'CDC says 3% of workers in surveyed meat processing plants tested positive for coronavirus',
      'story-content-id': 'b8460692-d844-4136-b261-ef1337743c16',
      slug: 'coronavirus/cdc-says-3-of-workers-in-surveyed-meat-processing-plants-tested-positive-for-coronavirus',
      'last-published-at': 1588384836298,
      subheadline:
        'About 3% of workers in over 100 meat processing plants have tested positive for the coronavirus, the Centers for Disease Control and Prevention said.',
      alternative: null,
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url':
          'https://image.cnbcfm.com/api/v1/image/106516116-1588349248893gettyimages-1005309372.jpeg?v=1588368935'
      },
      'published-at': 1588384836298,
      'item-type': 'story',
      id: 'b8460692-d844-4136-b261-ef1337743c16',
      'hero-image-s3-key':
        'ace/2020-05/c84a46f8-60b6-46e6-8906-22d9fb8b9966/106516116_1588349248893gettyimages_1005309372.jpeg',
      url:
        'https://ace-web.qtstage.io/coronavirus/cdc-says-3-of-workers-in-surveyed-meat-processing-plants-tested-positive-for-coronavirus',
      'author-id': 934395,
      'first-published-at': 1588378524777,
      'story-template': 'news-elsewhere',
      'created-at': 1588384840318,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ],
      metadata: {
        'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
        'reference-url':
          'https://www.cnbc.com/2020/05/01/coronavirus-cdc-says-3percent-of-workers-in-surveyed-meat-processing-plants-infected.html?utm_source=quintype',
        'imported-card-id': '26a04f08-3b29-4495-9232-3edec2f25eec',
        'card-share': { shareable: false }
      }
    },
    {
      access: 'subscription',
      'author-name': 'Feed Migrator',
      'read-time': 5,
      tags: [],
      headline: 'Mass. Walmart Closed for Cleaning After 23 Employees Test Positive for Coronavirus',
      'story-content-id': '242b58a9-a0b7-4764-9f3a-db88066ba259',
      slug: 'coronavirus/mass-walmart-closed-for-cleaning-after-23-employees-test-positive-for-coronavirus',
      'last-published-at': 1588286725472,
      subheadline:
        'A Walmart store in Worcester is set to undergo a deep cleaning Thursday after nearly two dozen workers tested positive for the new coronavirus this&nbsp;...',
      alternative: null,
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url': 'https://media.nbcboston.com/2019/09/Generic-Walmart.jpg?resize=850%2C478'
      },
      'published-at': 1588286725472,
      'item-type': 'story',
      id: '242b58a9-a0b7-4764-9f3a-db88066ba259',
      'hero-image-s3-key': 'ace/2020-04/2097ef7e-b1a3-48f5-9966-0a068bf17d06/Generic_Walmart.jpg',
      url:
        'https://ace-web.qtstage.io/coronavirus/mass-walmart-closed-for-cleaning-after-23-employees-test-positive-for-coronavirus',
      'author-id': 934395,
      'first-published-at': 1588269625814,
      'story-template': 'news-elsewhere',
      'created-at': 1588286729601,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ],
      metadata: {
        'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
        'reference-url':
          'https://www.nbcboston.com/news/coronavirus/mass-walmart-closed-for-cleaning-after-23-employees-test-positive-for-coronavirus/2116250?utm_source=quintype',
        'imported-card-id': 'eb2aa26d-b7ce-4951-9068-8f98906ff2d1',
        'card-share': { shareable: false }
      }
    },
    {
      access: 'subscription',
      'author-name': 'Feed Migrator',
      'read-time': 5,
      tags: [],
      headline: 'Indian man apprehended at US-Mexico border for illegal border crossing tests positive for ...',
      'story-content-id': '3c9ec517-8473-49e9-b5ac-5007fe4a8bd9',
      slug: 'coronavirus/indian-man-apprehended-at-us-mexico-border-for-illegal-border-crossing-tests-positive-for',
      'last-published-at': 1588266011153,
      subheadline:
        'Get all the latest news on coronavirus and more delivered daily to your inbox. Sign up here. An Indian national who was apprehended last week after&nbsp;...',
      alternative: null,
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url': 'https://static.foxnews.com/foxnews.com/content/uploads/2020/04/Border-Calexico-Reuters.jpg'
      },
      'published-at': 1588266011153,
      'item-type': 'story',
      id: '3c9ec517-8473-49e9-b5ac-5007fe4a8bd9',
      'hero-image-s3-key': 'ace/2020-04/d4f25a74-ff78-4b28-a526-04fa42fd3d4b/Border_Calexico_Reuters.jpg',
      url:
        'https://ace-web.qtstage.io/coronavirus/indian-man-apprehended-at-us-mexico-border-for-illegal-border-crossing-tests-positive-for',
      'author-id': 934395,
      'first-published-at': 1588248907588,
      'story-template': 'news-elsewhere',
      'created-at': 1588266016366,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ],
      metadata: {
        'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
        'reference-url':
          'https://www.foxnews.com/us/indian-man-apprehended-us-mexico-border-for-illegal-border-crossing-tests-positive-coronavirus?utm_source=quintype',
        'imported-card-id': 'c875ffa4-a84b-4342-9495-7101d9832a38',
        'card-share': { shareable: false }
      }
    },
    {
      access: 'subscription',
      'author-name': 'Feed Migrator',
      'read-time': 5,
      tags: [],
      headline:
        'Experimental coronavirus treatment remdesivir delivers &#39;positive data&#39; in trial, says developer Gilead',
      'story-content-id': 'f7d5ca77-a1d9-4df9-986c-445f8e64e8c6',
      slug:
        'coronavirus/experimental-coronavirus-treatment-remdesivir-delivers-39positive-data39-in-trial-says-developer-gilead',
      'last-published-at': 1588204807893,
      subheadline:
        'Fox News medical correspondent Dr. Marc Siegel weighs in. Get all the latest news on coronavirus and more delivered daily to your inbox. Sign up here.',
      alternative: null,
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url':
          'https://cf-images.us-east-1.prod.boltdns.net/v1/static/694940094001/6fe0e1f3-78f2-45dd-bb2a-332fceecb22a/b3125195-b4d7-42fd-b848-7683b71e5d57/1280x720/match/image.jpg'
      },
      'published-at': 1588204807893,
      'item-type': 'story',
      id: 'f7d5ca77-a1d9-4df9-986c-445f8e64e8c6',
      'hero-image-s3-key': 'ace/2020-04/db86dd5d-bff1-4f50-a5c3-a46fbe9b0d35/image.jpg',
      url:
        'https://ace-web.qtstage.io/coronavirus/experimental-coronavirus-treatment-remdesivir-delivers-39positive-data39-in-trial-says-developer-gilead',
      'author-id': 934395,
      'first-published-at': 1588192207676,
      'story-template': 'news-elsewhere',
      'created-at': 1588204831095,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ],
      metadata: {
        'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
        'reference-url':
          'https://www.foxnews.com/science/experimental-coronavirus-treatment-remdesivir-delivers-positive-data-in-trial-says-developer-gilead?utm_source=quintype',
        'imported-card-id': '5ad63fc2-3032-416d-bb47-432eb6dd12c8',
        'card-share': { shareable: false }
      }
    },
    {
      access: 'subscription',
      'author-name': 'Feed Migrator',
      'read-time': 5,
      tags: [],
      headline: 'Wall Street rallies on &#39;positive data&#39; from coronavirus treatment study',
      'story-content-id': 'bb9dcead-eb0d-4ef0-bcd4-9f980b74ce3b',
      slug: 'coronavirus/wall-street-rallies-on-39positive-data39-from-coronavirus-treatment-study',
      'last-published-at': 1588197608017,
      subheadline:
        'Wall Street rallied on Wednesday, with the Dow Jones Industrial Average jumping by around 450 points after a potential treatment for coronavirus&nbsp;...',
      alternative: null,
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url':
          'https://media2.s-nbcnews.com/j/newscms/2020_18/3328991/200429-new-york-stock-exchange-mn-0845_fe7dc9c53b28ea048cafade0c7d9e498.nbcnews-fp-1200-630.JPG'
      },
      'published-at': 1588197608017,
      'item-type': 'story',
      id: 'bb9dcead-eb0d-4ef0-bcd4-9f980b74ce3b',
      'hero-image-s3-key':
        'ace/2020-04/21ef017f-bffb-4e45-af84-f60a50d559c2/200429_new_york_stock_exchange_mn_0845_fe7dc9c53b28ea048cafade0c7d9e498_nbcnews_fp_1200_630.JPG',
      url:
        'https://ace-web.qtstage.io/coronavirus/wall-street-rallies-on-39positive-data39-from-coronavirus-treatment-study',
      'author-id': 934395,
      'first-published-at': 1588179604536,
      'story-template': 'news-elsewhere',
      'created-at': 1588197613138,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ],
      metadata: {
        'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
        'reference-url':
          'https://www.nbcnews.com/business/markets/wall-street-rallies-positive-data-coronavirus-treatment-study-n1195206?utm_source=quintype',
        'imported-card-id': '8496ed99-f0c4-484c-93a3-9ba4d4681306',
        'card-share': { shareable: false }
      }
    },
    {
      access: 'subscription',
      'author-name': 'Feed Migrator',
      'read-time': 5,
      tags: [],
      headline: 'Pug in North Carolina tests positive for coronavirus, may be first for dog in US',
      'story-content-id': '8af5b108-95ea-4346-8cfc-a84e45bc1f68',
      slug: 'coronavirus/pug-in-north-carolina-tests-positive-for-coronavirus-may-be-first-for-dog-in-us',
      'last-published-at': 1588112115263,
      subheadline:
        'Pug in North Carolina tests positive for coronavirus, may be first for dog in U.S.. The dog, Winston, is experiencing mild symptoms, his owner said. &quot;There&nbsp;...',
      alternative: null,
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url':
          'https://media3.s-nbcnews.com/j/newscms/2020_18/3327286/200428-pug-coronavirus-mn-0750_405691cc0910b02eadc209fc7d1ff09c.nbcnews-fp-1200-630.jpg'
      },
      'published-at': 1588112115263,
      'item-type': 'story',
      id: '8af5b108-95ea-4346-8cfc-a84e45bc1f68',
      'hero-image-s3-key':
        'ace/2020-04/dc292d18-23c5-4138-bb05-2b24ab72f435/200428_pug_coronavirus_mn_0750_405691cc0910b02eadc209fc7d1ff09c_nbcnews_fp_1200_630.jpg',
      url:
        'https://ace-web.qtstage.io/coronavirus/pug-in-north-carolina-tests-positive-for-coronavirus-may-be-first-for-dog-in-us',
      'author-id': 934395,
      'first-published-at': 1588093204410,
      'story-template': 'news-elsewhere',
      'created-at': 1588112120482,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ],
      metadata: {
        'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
        'reference-url':
          'https://www.nbcnews.com/news/animal-news/pug-north-carolina-tests-positive-coronavirus-may-be-first-dog-n1194096?utm_source=quintype',
        'imported-card-id': '24c101c2-5e58-4197-bf95-13c0dfed7966',
        'card-share': { shareable: false }
      }
    },
    {
      access: 'subscription',
      'author-name': 'Feed Migrator',
      'read-time': 5,
      tags: [],
      headline: 'Coronavirus updates: Two kids younger than 5 test positive in Matagorda County',
      'story-content-id': '02800882-c7a2-4fb3-a45d-cbb57ef6c765',
      slug: 'coronavirus/coronavirus-updates-two-kids-younger-than-5-test-positive-in-matagorda-county',
      'last-published-at': 1587948317699,
      subheadline:
        'HOUSTON — We are continuing to track the latest headlines and updates regarding the global coronavirus (COVID-19) pandemic. Get the latest&nbsp;...',
      alternative: null,
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url':
          'https://media.khou.com/assets/KHOU/images/6786744a-5e91-4145-889b-5ef77b9a683f/6786744a-5e91-4145-889b-5ef77b9a683f_1140x641.jpg'
      },
      'published-at': 1587948317699,
      'item-type': 'story',
      id: '02800882-c7a2-4fb3-a45d-cbb57ef6c765',
      'hero-image-s3-key':
        'ace/2020-04/299f1ae8-fce7-4a07-a357-00cf7cff3953/6786744a_5e91_4145_889b_5ef77b9a683f_1140x641.jpg',
      url:
        'https://ace-web.qtstage.io/coronavirus/coronavirus-updates-two-kids-younger-than-5-test-positive-in-matagorda-county',
      'author-id': 934395,
      'first-published-at': 1587942023234,
      'story-template': 'news-elsewhere',
      'created-at': 1587948322304,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ],
      metadata: {
        'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
        'reference-url':
          'https://www.khou.com/article/news/health/coronavirus/houston-coronavirus-updates-news-and-cases-sunday-april-26/285-deb2cfc6-d7b7-4b69-a6ac-a340f0665867?utm_source=quintype',
        'imported-card-id': '1d82a5e2-7f89-4dc5-8fbe-e3710ff07e3a',
        'card-share': { shareable: false }
      }
    }
  ],
  total: 133,
  query: 'positive',
  facets: {
    'section-name': {
      Film: 1,
      Politics: 2,
      Business: 1,
      Entertainment: 6,
      TestSection: 1,
      'Recent stories': 3,
      Coronavirus: 121,
      Lifestyle: 1,
      Sports: 1,
      News: 3,
      Health: 1,
      'Current Affairs': 2
    },
    'author-name': {
      'Ekta Patnaik': 1,
      'Pravin Atigre': 1,
      tester: 1,
      'Garima Kamboj': 2,
      'Swati Goyal': 4,
      'Rashmi J': 6
    },
    'story-template': { review: 2, video: 2, text: 6, photo: 1, 'live-blog': 3, 'news-elsewhere': 119 }
  },
  params: { q: 'positive', sort: 'score' },
  cacheKeys: [
    's/97/62c092f7',
    's/97/df37be96',
    's/97/882481dd',
    's/97/b8460692',
    's/97/242b58a9',
    's/97/3c9ec517',
    's/97/f7d5ca77',
    's/97/bb9dcead',
    's/97/8af5b108',
    's/97/02800882'
  ],
  title: 'Search Results for positive',
  collection: {
    id: '2402147414',
    items: [
      {
        id: '62c092f7-9270-4f34-a49e-7b83398511da',
        type: 'story',
        story: {
          'author-name': 'Feed Migrator',
          'read-time': 5,
          tags: [],
          headline: '122 More Coronavirus Deaths in Massachusetts, 1184 More Test Positive',
          'story-content-id': '62c092f7-9270-4f34-a49e-7b83398511da',
          slug: 'coronavirus/122-more-coronavirus-deaths-in-massachusetts-1184-more-test-positive',
          'last-published-at': 1588770025423,
          subheadline:
            'Gov. Charlie Baker toured a Fall River manufacturing facility that is shifting its focus to produce PPE. Massachusetts&#39; coronavirus death toll rose by&nbsp;...',
          alternative: null,
          sections: [
            {
              'domain-slug': null,
              slug: 'coronavirus',
              name: 'Coronavirus',
              'section-url': 'https://ace-web.qtstage.io/coronavirus',
              id: 42239,
              'parent-id': null,
              'display-name': 'Coronavirus',
              collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
              data: null
            }
          ],
          'hero-image-metadata': {
            'original-url': 'https://media.nbcboston.com/2019/09/Massachusetts-Coronavirus-Map-1-4.jpg?fit=850%2C478'
          },
          'published-at': 1588770025423,
          'item-type': 'story',
          id: '62c092f7-9270-4f34-a49e-7b83398511da',
          'hero-image-s3-key': 'ace/2020-05/1f8da8da-507f-4697-a15f-f49a9372aed6/Massachusetts_Coronavirus_Map_1_4.jpg',
          url:
            'https://ace-web.qtstage.io/coronavirus/122-more-coronavirus-deaths-in-massachusetts-1184-more-test-positive',
          'author-id': 934395,
          'first-published-at': 1588763722342,
          'story-template': 'news-elsewhere',
          'created-at': 1588770031768,
          authors: [
            {
              id: 934395,
              name: 'Feed Migrator',
              slug: 'feed-migrator',
              'avatar-url': null,
              'avatar-s3-key': null,
              'twitter-handle': null,
              bio: null
            }
          ],
          metadata: {
            'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
            'reference-url':
              'https://www.nbcboston.com/news/coronavirus/122-more-coronavirus-deaths-in-massachusetts-1184-more-test-positive/2119114?utm_source=quintype',
            'imported-card-id': 'fe9c1b68-b014-4aa1-b88d-86999e9f0d2f',
            'card-share': { shareable: false }
          }
        }
      },
      {
        id: 'df37be96-4b6d-456a-a60c-6fe938ebebe8',
        type: 'story',
        story: {
          'author-name': 'Feed Migrator',
          'read-time': 5,
          tags: [],
          headline: '130 More Coronavirus Deaths in Massachusetts, 1952 More Test Positive',
          'story-content-id': 'df37be96-4b6d-456a-a60c-6fe938ebebe8',
          slug: 'coronavirus/130-more-coronavirus-deaths-in-massachusetts-1952-more-test-positive',
          'last-published-at': 1588502711483,
          subheadline:
            'Mass. residents tell NBC 10 Boston they find Baker&#39;s mask order to be a good idea. Massachusetts&#39; coronavirus-related death toll rose by another 130,&nbsp;...',
          alternative: null,
          sections: [
            {
              'domain-slug': null,
              slug: 'coronavirus',
              name: 'Coronavirus',
              'section-url': 'https://ace-web.qtstage.io/coronavirus',
              id: 42239,
              'parent-id': null,
              'display-name': 'Coronavirus',
              collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
              data: null
            }
          ],
          'hero-image-metadata': {
            'original-url': 'https://media.nbcboston.com/2019/09/Massachusetts-Coronavirus-Map-1-4.jpg?fit=850%2C478'
          },
          'published-at': 1588502711483,
          'item-type': 'story',
          id: 'df37be96-4b6d-456a-a60c-6fe938ebebe8',
          'hero-image-s3-key': 'ace/2020-05/cf05320f-37d9-43a8-8a4e-7bd9f4e4a767/Massachusetts_Coronavirus_Map_1_4.jpg',
          url:
            'https://ace-web.qtstage.io/coronavirus/130-more-coronavirus-deaths-in-massachusetts-1952-more-test-positive',
          'author-id': 934395,
          'first-published-at': 1588457701595,
          'story-template': 'news-elsewhere',
          'created-at': 1588502717200,
          authors: [
            {
              id: 934395,
              name: 'Feed Migrator',
              slug: 'feed-migrator',
              'avatar-url': null,
              'avatar-s3-key': null,
              'twitter-handle': null,
              bio: null
            }
          ],
          metadata: {
            'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
            'reference-url':
              'https://www.nbcboston.com/news/coronavirus/130-more-coronavirus-deaths-in-massachusetts-1952-more-test-positive/2117465?utm_source=quintype',
            'imported-card-id': '13fb4555-bd2c-4054-b89a-2825b10b59d6',
            'card-share': { shareable: false }
          }
        }
      },
      {
        id: '882481dd-e526-4ff4-96d1-60cc28d86d72',
        type: 'story',
        story: {
          'author-name': 'Feed Migrator',
          'read-time': 5,
          tags: [],
          headline: 'Coronavirus in Massachusetts: 154 More Deaths, 2106 More People Test Positive',
          'story-content-id': '882481dd-e526-4ff4-96d1-60cc28d86d72',
          slug: 'coronavirus/coronavirus-in-massachusetts-154-more-deaths-2106-more-people-test-positive',
          'last-published-at': 1588419939710,
          subheadline:
            'Baker has been watching the state&#39;s coronavirus statistics carefully as he weighs when to ease the restrictions on movement and businesses in&nbsp;...',
          alternative: null,
          sections: [
            {
              'domain-slug': null,
              slug: 'coronavirus',
              name: 'Coronavirus',
              'section-url': 'https://ace-web.qtstage.io/coronavirus',
              id: 42239,
              'parent-id': null,
              'display-name': 'Coronavirus',
              collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
              data: null
            }
          ],
          'hero-image-metadata': {
            'original-url': 'https://media.nbcboston.com/2019/09/Massachusetts-Coronavirus-Map-1-4.jpg?fit=850%2C478'
          },
          'published-at': 1588419939710,
          'item-type': 'story',
          id: '882481dd-e526-4ff4-96d1-60cc28d86d72',
          'hero-image-s3-key': 'ace/2020-05/46a02445-19c7-4c6f-8341-8de6fe9764d2/Massachusetts_Coronavirus_Map_1_4.jpg',
          url:
            'https://ace-web.qtstage.io/coronavirus/coronavirus-in-massachusetts-154-more-deaths-2106-more-people-test-positive',
          'author-id': 934395,
          'first-published-at': 1588378522810,
          'story-template': 'news-elsewhere',
          'created-at': 1588419944041,
          authors: [
            {
              id: 934395,
              name: 'Feed Migrator',
              slug: 'feed-migrator',
              'avatar-url': null,
              'avatar-s3-key': null,
              'twitter-handle': null,
              bio: null
            }
          ],
          metadata: {
            'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
            'reference-url':
              'https://www.nbcboston.com/news/coronavirus/coronavirus-in-massachusetts-154-more-deaths-2106-more-people-test-positive/2117143?utm_source=quintype',
            'imported-card-id': '8720d082-20e8-44be-87b1-f84b9ae64a6b',
            'card-share': { shareable: false }
          }
        }
      },
      {
        id: 'b8460692-d844-4136-b261-ef1337743c16',
        type: 'story',
        story: {
          'author-name': 'Feed Migrator',
          'read-time': 5,
          tags: [],
          headline: 'CDC says 3% of workers in surveyed meat processing plants tested positive for coronavirus',
          'story-content-id': 'b8460692-d844-4136-b261-ef1337743c16',
          slug: 'coronavirus/cdc-says-3-of-workers-in-surveyed-meat-processing-plants-tested-positive-for-coronavirus',
          'last-published-at': 1588384836298,
          subheadline:
            'About 3% of workers in over 100 meat processing plants have tested positive for the coronavirus, the Centers for Disease Control and Prevention said.',
          alternative: null,
          sections: [
            {
              'domain-slug': null,
              slug: 'coronavirus',
              name: 'Coronavirus',
              'section-url': 'https://ace-web.qtstage.io/coronavirus',
              id: 42239,
              'parent-id': null,
              'display-name': 'Coronavirus',
              collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
              data: null
            }
          ],
          'hero-image-metadata': {
            'original-url':
              'https://image.cnbcfm.com/api/v1/image/106516116-1588349248893gettyimages-1005309372.jpeg?v=1588368935'
          },
          'published-at': 1588384836298,
          'item-type': 'story',
          id: 'b8460692-d844-4136-b261-ef1337743c16',
          'hero-image-s3-key':
            'ace/2020-05/c84a46f8-60b6-46e6-8906-22d9fb8b9966/106516116_1588349248893gettyimages_1005309372.jpeg',
          url:
            'https://ace-web.qtstage.io/coronavirus/cdc-says-3-of-workers-in-surveyed-meat-processing-plants-tested-positive-for-coronavirus',
          'author-id': 934395,
          'first-published-at': 1588378524777,
          'story-template': 'news-elsewhere',
          'created-at': 1588384840318,
          authors: [
            {
              id: 934395,
              name: 'Feed Migrator',
              slug: 'feed-migrator',
              'avatar-url': null,
              'avatar-s3-key': null,
              'twitter-handle': null,
              bio: null
            }
          ],
          metadata: {
            'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
            'reference-url':
              'https://www.cnbc.com/2020/05/01/coronavirus-cdc-says-3percent-of-workers-in-surveyed-meat-processing-plants-infected.html?utm_source=quintype',
            'imported-card-id': '26a04f08-3b29-4495-9232-3edec2f25eec',
            'card-share': { shareable: false }
          }
        }
      },
      {
        id: '242b58a9-a0b7-4764-9f3a-db88066ba259',
        type: 'story',
        story: {
          'author-name': 'Feed Migrator',
          'read-time': 5,
          tags: [],
          headline: 'Mass. Walmart Closed for Cleaning After 23 Employees Test Positive for Coronavirus',
          'story-content-id': '242b58a9-a0b7-4764-9f3a-db88066ba259',
          slug: 'coronavirus/mass-walmart-closed-for-cleaning-after-23-employees-test-positive-for-coronavirus',
          'last-published-at': 1588286725472,
          subheadline:
            'A Walmart store in Worcester is set to undergo a deep cleaning Thursday after nearly two dozen workers tested positive for the new coronavirus this&nbsp;...',
          alternative: null,
          sections: [
            {
              'domain-slug': null,
              slug: 'coronavirus',
              name: 'Coronavirus',
              'section-url': 'https://ace-web.qtstage.io/coronavirus',
              id: 42239,
              'parent-id': null,
              'display-name': 'Coronavirus',
              collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
              data: null
            }
          ],
          'hero-image-metadata': {
            'original-url': 'https://media.nbcboston.com/2019/09/Generic-Walmart.jpg?resize=850%2C478'
          },
          'published-at': 1588286725472,
          'item-type': 'story',
          id: '242b58a9-a0b7-4764-9f3a-db88066ba259',
          'hero-image-s3-key': 'ace/2020-04/2097ef7e-b1a3-48f5-9966-0a068bf17d06/Generic_Walmart.jpg',
          url:
            'https://ace-web.qtstage.io/coronavirus/mass-walmart-closed-for-cleaning-after-23-employees-test-positive-for-coronavirus',
          'author-id': 934395,
          'first-published-at': 1588269625814,
          'story-template': 'news-elsewhere',
          'created-at': 1588286729601,
          authors: [
            {
              id: 934395,
              name: 'Feed Migrator',
              slug: 'feed-migrator',
              'avatar-url': null,
              'avatar-s3-key': null,
              'twitter-handle': null,
              bio: null
            }
          ],
          metadata: {
            'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
            'reference-url':
              'https://www.nbcboston.com/news/coronavirus/mass-walmart-closed-for-cleaning-after-23-employees-test-positive-for-coronavirus/2116250?utm_source=quintype',
            'imported-card-id': 'eb2aa26d-b7ce-4951-9068-8f98906ff2d1',
            'card-share': { shareable: false }
          }
        }
      },
      {
        id: '3c9ec517-8473-49e9-b5ac-5007fe4a8bd9',
        type: 'story',
        story: {
          'author-name': 'Feed Migrator',
          'read-time': 5,
          tags: [],
          headline: 'Indian man apprehended at US-Mexico border for illegal border crossing tests positive for ...',
          'story-content-id': '3c9ec517-8473-49e9-b5ac-5007fe4a8bd9',
          slug: 'coronavirus/indian-man-apprehended-at-us-mexico-border-for-illegal-border-crossing-tests-positive-for',
          'last-published-at': 1588266011153,
          subheadline:
            'Get all the latest news on coronavirus and more delivered daily to your inbox. Sign up here. An Indian national who was apprehended last week after&nbsp;...',
          alternative: null,
          sections: [
            {
              'domain-slug': null,
              slug: 'coronavirus',
              name: 'Coronavirus',
              'section-url': 'https://ace-web.qtstage.io/coronavirus',
              id: 42239,
              'parent-id': null,
              'display-name': 'Coronavirus',
              collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
              data: null
            }
          ],
          'hero-image-metadata': {
            'original-url': 'https://static.foxnews.com/foxnews.com/content/uploads/2020/04/Border-Calexico-Reuters.jpg'
          },
          'published-at': 1588266011153,
          'item-type': 'story',
          id: '3c9ec517-8473-49e9-b5ac-5007fe4a8bd9',
          'hero-image-s3-key': 'ace/2020-04/d4f25a74-ff78-4b28-a526-04fa42fd3d4b/Border_Calexico_Reuters.jpg',
          url:
            'https://ace-web.qtstage.io/coronavirus/indian-man-apprehended-at-us-mexico-border-for-illegal-border-crossing-tests-positive-for',
          'author-id': 934395,
          'first-published-at': 1588248907588,
          'story-template': 'news-elsewhere',
          'created-at': 1588266016366,
          authors: [
            {
              id: 934395,
              name: 'Feed Migrator',
              slug: 'feed-migrator',
              'avatar-url': null,
              'avatar-s3-key': null,
              'twitter-handle': null,
              bio: null
            }
          ],
          metadata: {
            'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
            'reference-url':
              'https://www.foxnews.com/us/indian-man-apprehended-us-mexico-border-for-illegal-border-crossing-tests-positive-coronavirus?utm_source=quintype',
            'imported-card-id': 'c875ffa4-a84b-4342-9495-7101d9832a38',
            'card-share': { shareable: false }
          }
        }
      },
      {
        id: 'f7d5ca77-a1d9-4df9-986c-445f8e64e8c6',
        type: 'story',
        story: {
          'author-name': 'Feed Migrator',
          'read-time': 5,
          tags: [],
          headline:
            'Experimental coronavirus treatment remdesivir delivers &#39;positive data&#39; in trial, says developer Gilead',
          'story-content-id': 'f7d5ca77-a1d9-4df9-986c-445f8e64e8c6',
          slug:
            'coronavirus/experimental-coronavirus-treatment-remdesivir-delivers-39positive-data39-in-trial-says-developer-gilead',
          'last-published-at': 1588204807893,
          subheadline:
            'Fox News medical correspondent Dr. Marc Siegel weighs in. Get all the latest news on coronavirus and more delivered daily to your inbox. Sign up here.',
          alternative: null,
          sections: [
            {
              'domain-slug': null,
              slug: 'coronavirus',
              name: 'Coronavirus',
              'section-url': 'https://ace-web.qtstage.io/coronavirus',
              id: 42239,
              'parent-id': null,
              'display-name': 'Coronavirus',
              collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
              data: null
            }
          ],
          'hero-image-metadata': {
            'original-url':
              'https://cf-images.us-east-1.prod.boltdns.net/v1/static/694940094001/6fe0e1f3-78f2-45dd-bb2a-332fceecb22a/b3125195-b4d7-42fd-b848-7683b71e5d57/1280x720/match/image.jpg'
          },
          'published-at': 1588204807893,
          'item-type': 'story',
          id: 'f7d5ca77-a1d9-4df9-986c-445f8e64e8c6',
          'hero-image-s3-key': 'ace/2020-04/db86dd5d-bff1-4f50-a5c3-a46fbe9b0d35/image.jpg',
          url:
            'https://ace-web.qtstage.io/coronavirus/experimental-coronavirus-treatment-remdesivir-delivers-39positive-data39-in-trial-says-developer-gilead',
          'author-id': 934395,
          'first-published-at': 1588192207676,
          'story-template': 'news-elsewhere',
          'created-at': 1588204831095,
          authors: [
            {
              id: 934395,
              name: 'Feed Migrator',
              slug: 'feed-migrator',
              'avatar-url': null,
              'avatar-s3-key': null,
              'twitter-handle': null,
              bio: null
            }
          ],
          metadata: {
            'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
            'reference-url':
              'https://www.foxnews.com/science/experimental-coronavirus-treatment-remdesivir-delivers-positive-data-in-trial-says-developer-gilead?utm_source=quintype',
            'imported-card-id': '5ad63fc2-3032-416d-bb47-432eb6dd12c8',
            'card-share': { shareable: false }
          }
        }
      },
      {
        id: 'bb9dcead-eb0d-4ef0-bcd4-9f980b74ce3b',
        type: 'story',
        story: {
          'author-name': 'Feed Migrator',
          'read-time': 5,
          tags: [],
          headline: 'Wall Street rallies on &#39;positive data&#39; from coronavirus treatment study',
          'story-content-id': 'bb9dcead-eb0d-4ef0-bcd4-9f980b74ce3b',
          slug: 'coronavirus/wall-street-rallies-on-39positive-data39-from-coronavirus-treatment-study',
          'last-published-at': 1588197608017,
          subheadline:
            'Wall Street rallied on Wednesday, with the Dow Jones Industrial Average jumping by around 450 points after a potential treatment for coronavirus&nbsp;...',
          alternative: null,
          sections: [
            {
              'domain-slug': null,
              slug: 'coronavirus',
              name: 'Coronavirus',
              'section-url': 'https://ace-web.qtstage.io/coronavirus',
              id: 42239,
              'parent-id': null,
              'display-name': 'Coronavirus',
              collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
              data: null
            }
          ],
          'hero-image-metadata': {
            'original-url':
              'https://media2.s-nbcnews.com/j/newscms/2020_18/3328991/200429-new-york-stock-exchange-mn-0845_fe7dc9c53b28ea048cafade0c7d9e498.nbcnews-fp-1200-630.JPG'
          },
          'published-at': 1588197608017,
          'item-type': 'story',
          id: 'bb9dcead-eb0d-4ef0-bcd4-9f980b74ce3b',
          'hero-image-s3-key':
            'ace/2020-04/21ef017f-bffb-4e45-af84-f60a50d559c2/200429_new_york_stock_exchange_mn_0845_fe7dc9c53b28ea048cafade0c7d9e498_nbcnews_fp_1200_630.JPG',
          url:
            'https://ace-web.qtstage.io/coronavirus/wall-street-rallies-on-39positive-data39-from-coronavirus-treatment-study',
          'author-id': 934395,
          'first-published-at': 1588179604536,
          'story-template': 'news-elsewhere',
          'created-at': 1588197613138,
          authors: [
            {
              id: 934395,
              name: 'Feed Migrator',
              slug: 'feed-migrator',
              'avatar-url': null,
              'avatar-s3-key': null,
              'twitter-handle': null,
              bio: null
            }
          ],
          metadata: {
            'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
            'reference-url':
              'https://www.nbcnews.com/business/markets/wall-street-rallies-positive-data-coronavirus-treatment-study-n1195206?utm_source=quintype',
            'imported-card-id': '8496ed99-f0c4-484c-93a3-9ba4d4681306',
            'card-share': { shareable: false }
          }
        }
      },
      {
        id: '8af5b108-95ea-4346-8cfc-a84e45bc1f68',
        type: 'story',
        story: {
          'author-name': 'Feed Migrator',
          'read-time': 5,
          tags: [],
          headline: 'Pug in North Carolina tests positive for coronavirus, may be first for dog in US',
          'story-content-id': '8af5b108-95ea-4346-8cfc-a84e45bc1f68',
          slug: 'coronavirus/pug-in-north-carolina-tests-positive-for-coronavirus-may-be-first-for-dog-in-us',
          'last-published-at': 1588112115263,
          subheadline:
            'Pug in North Carolina tests positive for coronavirus, may be first for dog in U.S.. The dog, Winston, is experiencing mild symptoms, his owner said. &quot;There&nbsp;...',
          alternative: null,
          sections: [
            {
              'domain-slug': null,
              slug: 'coronavirus',
              name: 'Coronavirus',
              'section-url': 'https://ace-web.qtstage.io/coronavirus',
              id: 42239,
              'parent-id': null,
              'display-name': 'Coronavirus',
              collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
              data: null
            }
          ],
          'hero-image-metadata': {
            'original-url':
              'https://media3.s-nbcnews.com/j/newscms/2020_18/3327286/200428-pug-coronavirus-mn-0750_405691cc0910b02eadc209fc7d1ff09c.nbcnews-fp-1200-630.jpg'
          },
          'published-at': 1588112115263,
          'item-type': 'story',
          id: '8af5b108-95ea-4346-8cfc-a84e45bc1f68',
          'hero-image-s3-key':
            'ace/2020-04/dc292d18-23c5-4138-bb05-2b24ab72f435/200428_pug_coronavirus_mn_0750_405691cc0910b02eadc209fc7d1ff09c_nbcnews_fp_1200_630.jpg',
          url:
            'https://ace-web.qtstage.io/coronavirus/pug-in-north-carolina-tests-positive-for-coronavirus-may-be-first-for-dog-in-us',
          'author-id': 934395,
          'first-published-at': 1588093204410,
          'story-template': 'news-elsewhere',
          'created-at': 1588112120482,
          authors: [
            {
              id: 934395,
              name: 'Feed Migrator',
              slug: 'feed-migrator',
              'avatar-url': null,
              'avatar-s3-key': null,
              'twitter-handle': null,
              bio: null
            }
          ],
          metadata: {
            'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
            'reference-url':
              'https://www.nbcnews.com/news/animal-news/pug-north-carolina-tests-positive-coronavirus-may-be-first-dog-n1194096?utm_source=quintype',
            'imported-card-id': '24c101c2-5e58-4197-bf95-13c0dfed7966',
            'card-share': { shareable: false }
          }
        }
      },
      {
        id: '02800882-c7a2-4fb3-a45d-cbb57ef6c765',
        type: 'story',
        story: {
          'author-name': 'Feed Migrator',
          'read-time': 5,
          tags: [],
          headline: 'Coronavirus updates: Two kids younger than 5 test positive in Matagorda County',
          'story-content-id': '02800882-c7a2-4fb3-a45d-cbb57ef6c765',
          slug: 'coronavirus/coronavirus-updates-two-kids-younger-than-5-test-positive-in-matagorda-county',
          'last-published-at': 1587948317699,
          subheadline:
            'HOUSTON — We are continuing to track the latest headlines and updates regarding the global coronavirus (COVID-19) pandemic. Get the latest&nbsp;...',
          alternative: null,
          sections: [
            {
              'domain-slug': null,
              slug: 'coronavirus',
              name: 'Coronavirus',
              'section-url': 'https://ace-web.qtstage.io/coronavirus',
              id: 42239,
              'parent-id': null,
              'display-name': 'Coronavirus',
              collection: { slug: 'coronavirus', name: 'Coronavirus', id: 92836 },
              data: null
            }
          ],
          'hero-image-metadata': {
            'original-url':
              'https://media.khou.com/assets/KHOU/images/6786744a-5e91-4145-889b-5ef77b9a683f/6786744a-5e91-4145-889b-5ef77b9a683f_1140x641.jpg'
          },
          'published-at': 1587948317699,
          'item-type': 'story',
          id: '02800882-c7a2-4fb3-a45d-cbb57ef6c765',
          'hero-image-s3-key':
            'ace/2020-04/299f1ae8-fce7-4a07-a357-00cf7cff3953/6786744a_5e91_4145_889b_5ef77b9a683f_1140x641.jpg',
          url:
            'https://ace-web.qtstage.io/coronavirus/coronavirus-updates-two-kids-younger-than-5-test-positive-in-matagorda-county',
          'author-id': 934395,
          'first-published-at': 1587942023234,
          'story-template': 'news-elsewhere',
          'created-at': 1587948322304,
          authors: [
            {
              id: 934395,
              name: 'Feed Migrator',
              slug: 'feed-migrator',
              'avatar-url': null,
              'avatar-s3-key': null,
              'twitter-handle': null,
              bio: null
            }
          ],
          metadata: {
            'story-attributes': { syndicatedfrom: ['Google Alerts - Coronavirus'], 'syndicated-categories': [] },
            'reference-url':
              'https://www.khou.com/article/news/health/coronavirus/houston-coronavirus-updates-news-and-cases-sunday-april-26/285-deb2cfc6-d7b7-4b69-a6ac-a340f0665867?utm_source=quintype',
            'imported-card-id': '1d82a5e2-7f89-4dc5-8fbe-e3710ff07e3a',
            'card-share': { shareable: false }
          }
        }
      }
    ],
    metadata: {
      'cover-image': {
        caption: 'Some Politician',
        'cover-image-metadata': { 'focus-point': [355, 204], height: 600, 'mime-type': 'image/jpeg', width: 600 },
        'cover-image-s3-key': 'vikatan/2019-01/f0b702ac-bfd1-4dd6-8e33-52cbb0bb3153/143203_thumb.jpg',
        'cover-image-url':
          'http://d9zv4zsfqrm9s.cloudfront.net/ace/2017-12/c811b029-962f-4364-b376-ec1548d67494/earth.jpg'
      },
      section: [{ id: 11181, name: 'Health', 'parent-id': null, 'tree-ids': [11181] }]
    },
    name: 'Politics',
    slug: 'collection-slug',
    summary: 'This is a collection concerned with politics',
    template: '',
    type: 'collection'
  },
  navigationMenu: [
    {
      'updated-at': 1587024605840,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'sidebar-menu',
      'item-id': null,
      rank: 822,
      title: 'Times',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 3405,
      'parent-id': null,
      url: 'http://transportandlogisticsme.com/',
      'entity-name': null,
      'created-at': 1538040312837,
      'section-name': null,
      'menu-group-id': 42,
      data: { color: '#000000', link: 'http://transportandlogisticsme.com/' },
      children: [],
      completeUrl: 'http://transportandlogisticsme.com/',
      isExternalLink: true
    },
    {
      'updated-at': 1587024605846,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': null,
      rank: 3406,
      title: 'Cities',
      'item-type': 'placeholder',
      'section-slug': null,
      'tag-slug': null,
      id: 3218,
      'parent-id': null,
      url: null,
      'entity-name': null,
      'created-at': 1537430266340,
      'section-name': null,
      'menu-group-id': 42,
      data: { color: '#000000', link: '#' },
      children: [
        {
          'updated-at': 1583993743539,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'secondary-menu',
          'item-id': 6657,
          rank: 8979,
          title: 'Global Issues',
          'item-type': 'section',
          'section-slug': 'global-issues',
          'tag-slug': null,
          id: 8979,
          'parent-id': 3218,
          url: 'https://ace-web.qtstage.io/news/global-issues',
          'entity-name': null,
          'created-at': 1583993743539,
          'section-name': 'Global Issues',
          'menu-group-id': 42,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/global-issues'
        }
      ],
      completeUrl: '/#'
    },
    {
      'updated-at': 1580452283407,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'header',
      'item-id': null,
      rank: 3452,
      title: 'Testing Header Menu',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 5745,
      'parent-id': null,
      url: 'google.com',
      'entity-name': null,
      'created-at': 1552558276605,
      'section-name': null,
      'menu-group-id': 44,
      data: { color: '#000000', link: 'google.com' },
      children: [],
      completeUrl: 'google.com',
      isExternalLink: true
    },
    {
      'updated-at': 1548926316068,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': 6447,
      rank: 3453,
      title: 'Recent stories',
      'item-type': 'section',
      'section-slug': 'entertainment',
      'tag-slug': null,
      id: 3452,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/entertainment',
      'entity-name': null,
      'created-at': 1538626542936,
      'section-name': 'Entertainment',
      'menu-group-id': 42,
      data: { color: '#000000' },
      children: [
        {
          'updated-at': 1548926316068,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'secondary-menu',
          'item-id': 6449,
          rank: 4060,
          title: 'CurrentAffairs',
          'item-type': 'section',
          'section-slug': 'current-affairs',
          'tag-slug': null,
          id: 4060,
          'parent-id': 3452,
          url: 'https://ace-web.qtstage.io/current-affairs',
          'entity-name': null,
          'created-at': 1542620427491,
          'section-name': 'Current Affairs',
          'menu-group-id': 42,
          data: { color: '#000000' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/current-affairs'
        }
      ],
      completeUrl: 'https://ace-web.qtstage.io/news/entertainment'
    },
    {
      'updated-at': 1548926316068,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': 164216,
      rank: 3675,
      title: 'States',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 3675,
      'parent-id': null,
      url: 'https://www.pennews.net/',
      'entity-name': null,
      'created-at': 1540814301207,
      'section-name': null,
      'menu-group-id': 42,
      data: { color: '#000000', link: 'https://www.pennews.net/' },
      children: [
        {
          'updated-at': 1548926316068,
          'tag-name': 'tags1',
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'secondary-menu',
          'item-id': 164275,
          rank: 4085,
          title: 'State1',
          'item-type': 'tag',
          'section-slug': null,
          'tag-slug': 'tags1',
          id: 4085,
          'parent-id': 3675,
          url: 'https://ace-web.qtstage.io/topic/tags1',
          'entity-name': null,
          'created-at': 1542804435609,
          'section-name': null,
          'menu-group-id': 42,
          data: { color: '#000000' },
          children: [],
          completeUrl: '/topic/tags1'
        }
      ],
      completeUrl: 'https://www.pennews.net/',
      isExternalLink: true
    },
    {
      'updated-at': 1548926316068,
      'tag-name': 'tags1',
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': 164275,
      rank: 4062,
      title: 'Cinema',
      'item-type': 'tag',
      'section-slug': null,
      'tag-slug': 'tags1',
      id: 4062,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/topic/tags1',
      'entity-name': null,
      'created-at': 1542622548832,
      'section-name': null,
      'menu-group-id': 42,
      data: { color: '#000000' },
      children: [],
      completeUrl: '/topic/tags1'
    },
    {
      'updated-at': 1566895265037,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'sidebar-menu',
      'item-id': 6657,
      rank: 4067,
      title: 'Economy',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 4070,
      'parent-id': null,
      url: 'https://beta-madrid.quintype.io',
      'entity-name': null,
      'created-at': 1542802433571,
      'section-name': null,
      'menu-group-id': 43,
      data: { color: '#000000', link: 'https://beta-madrid.quintype.io' },
      children: [],
      completeUrl: 'https://beta-madrid.quintype.io',
      isExternalLink: true
    },
    {
      'updated-at': 1581326348189,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 11181,
      rank: 4070,
      title: 'Health',
      'item-type': 'section',
      'section-slug': 'health',
      'tag-slug': null,
      id: 4067,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/health',
      'entity-name': null,
      'created-at': 1542801924654,
      'section-name': 'Health',
      'menu-group-id': 43,
      data: { color: '#000000' },
      children: [
        {
          'updated-at': 1584347702399,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': null,
          rank: 3146,
          title: 'cricket',
          'item-type': 'link',
          'section-slug': null,
          'tag-slug': null,
          id: 8130,
          'parent-id': 4067,
          url: 'https://www.carolina.com/',
          'entity-name': null,
          'created-at': 1578464107401,
          'section-name': null,
          'menu-group-id': 43,
          data: { color: '#FFFFFF', link: 'https://www.carolina.com/' },
          children: [],
          completeUrl: 'https://www.carolina.com/',
          isExternalLink: true
        }
      ],
      completeUrl: 'https://ace-web.qtstage.io/news/health'
    },
    {
      'updated-at': 1582612321395,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'sidebar-menu',
      'item-id': 12193,
      rank: 4072,
      title: 'Reviews',
      'item-type': 'section',
      'section-slug': 'testsection1',
      'tag-slug': null,
      id: 4081,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/testsection1',
      'entity-name': null,
      'created-at': 1542803868459,
      'section-name': 'Testsection1',
      'menu-group-id': 45,
      data: { color: '#000000' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/testsection1'
    },
    {
      'updated-at': 1548926316072,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 5751,
      rank: 4077,
      title: 'Lifestyle',
      'item-type': 'section',
      'section-slug': 'lifestyle',
      'tag-slug': null,
      id: 4077,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/lifestyle',
      'entity-name': null,
      'created-at': 1542803308765,
      'section-name': 'News',
      'menu-group-id': 43,
      data: { color: '#000000' },
      children: [
        {
          'updated-at': 1552564045310,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'header',
          'item-id': 5751,
          rank: 5745,
          title: 'MyMenuItem',
          'item-type': 'section',
          'section-slug': 'lifestyle',
          'tag-slug': null,
          id: 5185,
          'parent-id': 4077,
          url: 'https://ace-web.qtstage.io/news/lifestyle',
          'entity-name': null,
          'created-at': 1549614180849,
          'section-name': 'News',
          'menu-group-id': null,
          data: { color: '#000000' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/lifestyle'
        }
      ],
      completeUrl: 'https://ace-web.qtstage.io/news/lifestyle'
    },
    {
      'updated-at': 1584347716157,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': null,
      rank: 4078,
      title: 'Food culture-rename',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 1522,
      'parent-id': null,
      url: 'https://facebook.com',
      'entity-name': null,
      'created-at': 1528779877461,
      'section-name': null,
      'menu-group-id': 43,
      data: { color: '#000000', link: 'https://facebook.com' },
      children: [
        {
          'updated-at': 1557474959822,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'secondary-menu',
          'item-id': 18454,
          rank: 1073,
          title: 'Politics',
          'item-type': 'section',
          'section-slug': 'elections-2018',
          'tag-slug': null,
          id: 818,
          'parent-id': 1522,
          url: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018',
          'entity-name': null,
          'created-at': 1505453353162,
          'section-name': 'Elections 2018',
          'menu-group-id': 42,
          data: { color: '#47cd85', link: '' },
          children: [
            {
              'updated-at': 1564466723994,
              'tag-name': null,
              'entity-properties': null,
              'entity-slug': null,
              'publisher-id': 97,
              'menu-group-slug': 'secondary-menu',
              'item-id': 5670,
              rank: 4065,
              title: 'News',
              'item-type': 'section',
              'section-slug': 'news',
              'tag-slug': null,
              id: 4065,
              'parent-id': 818,
              url: 'https://ace-web.qtstage.io/recent-stories/news',
              'entity-name': null,
              'created-at': 1542794210872,
              'section-name': 'News',
              'menu-group-id': 42,
              data: { color: '#000000' },
              children: [
                {
                  'updated-at': 1564466675429,
                  'tag-name': null,
                  'entity-properties': null,
                  'entity-slug': null,
                  'publisher-id': 97,
                  'menu-group-slug': 'secondary-menu',
                  'item-id': 23194,
                  rank: 3219,
                  title: 'INDIA',
                  'item-type': 'section',
                  'section-slug': 'entertainment',
                  'tag-slug': null,
                  id: 3217,
                  'parent-id': 4065,
                  url: 'https://ace-web.qtstage.io/entertainment',
                  'entity-name': null,
                  'created-at': 1537430249429,
                  'section-name': 'Entertainment',
                  'menu-group-id': 42,
                  data: { color: '#000000', link: '#' },
                  children: [
                    {
                      'updated-at': 1552564285505,
                      'tag-name': null,
                      'entity-properties': null,
                      'entity-slug': null,
                      'publisher-id': 97,
                      'menu-group-slug': 'secondary-menu',
                      'item-id': 6657,
                      rank: 4595,
                      title: 'Asia',
                      'item-type': 'section',
                      'section-slug': 'global-issues',
                      'tag-slug': null,
                      id: 4101,
                      'parent-id': 3217,
                      url: 'https://ace-web.qtstage.io/news/global-issues',
                      'entity-name': null,
                      'created-at': 1542876792540,
                      'section-name': 'Global Issues',
                      'menu-group-id': 42,
                      data: { color: '#000000' },
                      children: [],
                      completeUrl: 'https://ace-web.qtstage.io/news/global-issues'
                    }
                  ],
                  completeUrl: 'https://ace-web.qtstage.io/entertainment'
                },
                {
                  'updated-at': 1548926316072,
                  'tag-name': null,
                  'entity-properties': null,
                  'entity-slug': null,
                  'publisher-id': 97,
                  'menu-group-slug': 'default',
                  'item-id': 5670,
                  rank: 4068,
                  title: 'International',
                  'item-type': 'section',
                  'section-slug': 'news',
                  'tag-slug': null,
                  id: 4068,
                  'parent-id': 4065,
                  url: 'https://ace-web.qtstage.io/recent-stories/news',
                  'entity-name': null,
                  'created-at': 1542802305382,
                  'section-name': 'News',
                  'menu-group-id': 43,
                  data: { color: '#000000' },
                  children: [],
                  completeUrl: 'https://ace-web.qtstage.io/recent-stories/news'
                }
              ],
              completeUrl: 'https://ace-web.qtstage.io/recent-stories/news'
            }
          ],
          completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018'
        },
        {
          'updated-at': 1548926316068,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'secondary-menu',
          'item-id': 6449,
          rank: 4069,
          title: 'National',
          'item-type': 'section',
          'section-slug': 'current-affairs',
          'tag-slug': null,
          id: 4069,
          'parent-id': 1522,
          url: 'https://ace-web.qtstage.io/current-affairs',
          'entity-name': null,
          'created-at': 1542802374551,
          'section-name': 'Current Affairs',
          'menu-group-id': 42,
          data: { color: '#000000' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/current-affairs'
        },
        {
          'updated-at': 1557474975893,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'sidebar-menu',
          'item-id': 5752,
          rank: 4071,
          title: 'Weekend Special',
          'item-type': 'link',
          'section-slug': null,
          'tag-slug': null,
          id: 4071,
          'parent-id': 1522,
          url: 'https://www.google.com/',
          'entity-name': null,
          'created-at': 1542802544832,
          'section-name': null,
          'menu-group-id': 45,
          data: { color: '#000000', link: 'https://www.google.com/' },
          children: [
            {
              'updated-at': 1557475002240,
              'tag-name': null,
              'entity-properties': null,
              'entity-slug': null,
              'publisher-id': 97,
              'menu-group-slug': 'sidebar-menu',
              'item-id': 5746,
              rank: 4074,
              title: 'Sunday',
              'item-type': 'link',
              'section-slug': null,
              'tag-slug': null,
              id: 4074,
              'parent-id': 4071,
              url: 'https://www.google.com/',
              'entity-name': null,
              'created-at': 1542802888444,
              'section-name': null,
              'menu-group-id': 45,
              data: { color: '#000000', link: 'https://www.google.com/' },
              children: [],
              completeUrl: 'https://www.google.com/',
              isExternalLink: true
            },
            {
              'updated-at': 1548926316080,
              'tag-name': 'modi',
              'entity-properties': null,
              'entity-slug': null,
              'publisher-id': 97,
              'menu-group-slug': 'sidebar-menu',
              'item-id': 201129,
              rank: 4075,
              title: 'Saturday',
              'item-type': 'tag',
              'section-slug': null,
              'tag-slug': 'modi',
              id: 4075,
              'parent-id': 4071,
              url: 'https://ace-web.qtstage.io/topic/modi',
              'entity-name': null,
              'created-at': 1542802966656,
              'section-name': null,
              'menu-group-id': 45,
              data: { color: '#000000' },
              children: [],
              completeUrl: '/topic/modi'
            }
          ],
          completeUrl: 'https://www.google.com/',
          isExternalLink: true
        },
        {
          'updated-at': 1584347746334,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': null,
          rank: 7123,
          title: 'Film',
          'item-type': 'link',
          'section-slug': null,
          'tag-slug': null,
          id: 848,
          'parent-id': 1522,
          url: 'https://www.youtube.com/',
          'entity-name': null,
          'created-at': 1506336796940,
          'section-name': null,
          'menu-group-id': 43,
          data: { color: '#49c6dd', link: 'https://www.youtube.com/' },
          children: [
            {
              'updated-at': 1557472042370,
              'tag-name': null,
              'entity-properties': null,
              'entity-slug': null,
              'publisher-id': 97,
              'menu-group-slug': 'header',
              'item-id': 5670,
              rank: 1318,
              title: 'News',
              'item-type': 'section',
              'section-slug': 'news',
              'tag-slug': null,
              id: 822,
              'parent-id': 848,
              url: 'https://ace-web.qtstage.io/recent-stories/news',
              'entity-name': null,
              'created-at': 1505811047360,
              'section-name': 'News',
              'menu-group-id': 44,
              data: { color: '#65036b', link: '' },
              children: [
                {
                  'updated-at': 1557472039453,
                  'tag-name': null,
                  'entity-properties': null,
                  'entity-slug': null,
                  'publisher-id': 97,
                  'menu-group-slug': 'secondary-menu',
                  'item-id': 5752,
                  rank: 1320,
                  title: 'Business',
                  'item-type': 'section',
                  'section-slug': 'business',
                  'tag-slug': null,
                  id: 1073,
                  'parent-id': 822,
                  url: 'https://ace-web.qtstage.io/recent-stories/news/business',
                  'entity-name': null,
                  'created-at': 1507113313397,
                  'section-name': 'Business',
                  'menu-group-id': 42,
                  data: { color: '#43fc5c', link: '' },
                  children: [],
                  completeUrl: 'https://ace-web.qtstage.io/recent-stories/news/business'
                },
                {
                  'updated-at': 1552564270740,
                  'tag-name': null,
                  'entity-properties': null,
                  'entity-slug': null,
                  'publisher-id': 97,
                  'menu-group-slug': 'secondary-menu',
                  'item-id': 6449,
                  rank: 5170,
                  title: 'My menu',
                  'item-type': 'section',
                  'section-slug': 'current-affairs',
                  'tag-slug': null,
                  id: 5169,
                  'parent-id': 822,
                  url: 'https://ace-web.qtstage.io/current-affairs',
                  'entity-name': null,
                  'created-at': 1549033379532,
                  'section-name': 'Current Affairs',
                  'menu-group-id': null,
                  data: { color: '#000000' },
                  children: [],
                  completeUrl: 'https://ace-web.qtstage.io/current-affairs'
                },
                {
                  'updated-at': 1552564047190,
                  'tag-name': null,
                  'entity-properties': null,
                  'entity-slug': null,
                  'publisher-id': 97,
                  'menu-group-slug': 'sidebar-menu',
                  'item-id': 18454,
                  rank: 5185,
                  title: 'Mymenu',
                  'item-type': 'section',
                  'section-slug': 'elections-2018',
                  'tag-slug': null,
                  id: 5170,
                  'parent-id': 822,
                  url: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018',
                  'entity-name': null,
                  'created-at': 1549033759338,
                  'section-name': 'Elections 2018',
                  'menu-group-id': null,
                  data: { color: '#000000' },
                  children: [],
                  completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018'
                }
              ],
              completeUrl: 'https://ace-web.qtstage.io/recent-stories/news'
            },
            {
              'updated-at': 1548926316072,
              'tag-name': null,
              'entity-properties': null,
              'entity-slug': null,
              'publisher-id': 97,
              'menu-group-slug': 'default',
              'item-id': null,
              rank: 4066,
              title: 'sports',
              'item-type': 'link',
              'section-slug': null,
              'tag-slug': null,
              id: 4066,
              'parent-id': 848,
              url: 'https://madrid.quintype.io',
              'entity-name': null,
              'created-at': 1542801843550,
              'section-name': null,
              'menu-group-id': 43,
              data: { color: '#000000', link: 'https://madrid.quintype.io' },
              children: [],
              completeUrl: 'https://madrid.quintype.io',
              isExternalLink: true
            }
          ],
          completeUrl: 'https://www.youtube.com/',
          isExternalLink: true
        },
        {
          'updated-at': 1574415823090,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 19005,
          rank: 7604,
          title: 'Test 1',
          'item-type': 'section',
          'section-slug': 'empty-collection',
          'tag-slug': null,
          id: 7606,
          'parent-id': 1522,
          url: 'https://ace-web.qtstage.io/news/empty-collection',
          'entity-name': null,
          'created-at': 1574415785066,
          'section-name': 'Empty Collection',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/empty-collection'
        },
        {
          'updated-at': 1574415823090,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 18454,
          rank: 7606,
          title: 'Test 1',
          'item-type': 'section',
          'section-slug': 'elections-2018',
          'tag-slug': null,
          id: 7604,
          'parent-id': 1522,
          url: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018',
          'entity-name': null,
          'created-at': 1574415770143,
          'section-name': 'Elections 2018',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018'
        },
        {
          'updated-at': 1574415827695,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 12026,
          rank: 7607,
          title: 'Business Business Business BusinessBusiness Business',
          'item-type': 'section',
          'section-slug': 'firstsubsection',
          'tag-slug': null,
          id: 7608,
          'parent-id': 1522,
          url: 'https://ace-web.qtstage.io/news/firstsection/firstsubsection',
          'entity-name': null,
          'created-at': 1574415802233,
          'section-name': 'Firstsubsection',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/firstsection/firstsubsection'
        },
        {
          'updated-at': 1574415827694,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 19005,
          rank: 7608,
          title: 'Handpicked for me',
          'item-type': 'section',
          'section-slug': 'empty-collection',
          'tag-slug': null,
          id: 7607,
          'parent-id': 1522,
          url: 'https://ace-web.qtstage.io/news/empty-collection',
          'entity-name': null,
          'created-at': 1574415793696,
          'section-name': 'Empty Collection',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/empty-collection'
        },
        {
          'updated-at': 1574415899052,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 18454,
          rank: 7613,
          title: 'Handpicked for me',
          'item-type': 'section',
          'section-slug': 'elections-2018',
          'tag-slug': null,
          id: 7613,
          'parent-id': 1522,
          url: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018',
          'entity-name': null,
          'created-at': 1574415899052,
          'section-name': 'Elections 2018',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018'
        },
        {
          'updated-at': 1574415918042,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 19005,
          rank: 7614,
          title: 'Bollywood Bollywood Bollywood Bollywood BollywoodBollywood',
          'item-type': 'section',
          'section-slug': 'empty-collection',
          'tag-slug': null,
          id: 7614,
          'parent-id': 1522,
          url: 'https://ace-web.qtstage.io/news/empty-collection',
          'entity-name': null,
          'created-at': 1574415918042,
          'section-name': 'Empty Collection',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/empty-collection'
        }
      ],
      completeUrl: 'https://facebook.com',
      isExternalLink: true
    },
    {
      'updated-at': 1578465601877,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 6452,
      rank: 4079,
      title: 'Weather',
      'item-type': 'section',
      'section-slug': 'weather',
      'tag-slug': null,
      id: 4079,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/lifestyle/weather',
      'entity-name': null,
      'created-at': 1542803359151,
      'section-name': 'Weather',
      'menu-group-id': 43,
      data: { color: '#000000' },
      children: [
        {
          'updated-at': 1555481131134,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 6448,
          rank: 4084,
          title: 'climate',
          'item-type': 'link',
          'section-slug': null,
          'tag-slug': null,
          id: 4084,
          'parent-id': 4079,
          url: 'https://www.google.com/',
          'entity-name': null,
          'created-at': 1542804158991,
          'section-name': null,
          'menu-group-id': 43,
          data: { color: '#000000', link: 'https://www.google.com/' },
          children: [],
          completeUrl: 'https://www.google.com/',
          isExternalLink: true
        },
        {
          'updated-at': 1574415715331,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 19005,
          rank: 7599,
          title: 'Handpicked for me',
          'item-type': 'section',
          'section-slug': 'empty-collection',
          'tag-slug': null,
          id: 7599,
          'parent-id': 4079,
          url: 'https://ace-web.qtstage.io/news/empty-collection',
          'entity-name': null,
          'created-at': 1574415715331,
          'section-name': 'Empty Collection',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/empty-collection'
        },
        {
          'updated-at': 1574415738894,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 19005,
          rank: 7600,
          title: 'Bollywood Bollywood Bollywood Bollywood BollywoodBollywood',
          'item-type': 'section',
          'section-slug': 'empty-collection',
          'tag-slug': null,
          id: 7600,
          'parent-id': 4079,
          url: 'https://ace-web.qtstage.io/news/empty-collection',
          'entity-name': null,
          'created-at': 1574415738894,
          'section-name': 'Empty Collection',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/empty-collection'
        },
        {
          'updated-at': 1574415749001,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 18454,
          rank: 7602,
          title: 'Business Business Business BusinessBusiness Business',
          'item-type': 'section',
          'section-slug': 'elections-2018',
          'tag-slug': null,
          id: 7602,
          'parent-id': 4079,
          url: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018',
          'entity-name': null,
          'created-at': 1574415749001,
          'section-name': 'Elections 2018',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018'
        },
        {
          'updated-at': 1574415758704,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 6448,
          rank: 7603,
          title: 'Movies',
          'item-type': 'section',
          'section-slug': 'food-culture',
          'tag-slug': null,
          id: 7603,
          'parent-id': 4079,
          url: 'https://ace-web.qtstage.io/food-culture',
          'entity-name': null,
          'created-at': 1574415758704,
          'section-name': 'Food culture',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/food-culture'
        },
        {
          'updated-at': 1574415848974,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 18454,
          rank: 7609,
          title: 'Handpicked for me',
          'item-type': 'section',
          'section-slug': 'elections-2018',
          'tag-slug': null,
          id: 7609,
          'parent-id': 4079,
          url: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018',
          'entity-name': null,
          'created-at': 1574415848974,
          'section-name': 'Elections 2018',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018'
        },
        {
          'updated-at': 1574415862294,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 32459,
          rank: 7610,
          title: 'Movies',
          'item-type': 'section',
          'section-slug': 'criminal',
          'tag-slug': null,
          id: 7610,
          'parent-id': 4079,
          url: 'https://ace-web.qtstage.io/news/criminal',
          'entity-name': null,
          'created-at': 1574415862294,
          'section-name': 'Criminal',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/criminal'
        },
        {
          'updated-at': 1574415880437,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 6447,
          rank: 7611,
          title: 'Bollywood Bollywood Bollywood Bollywood BollywoodBollywood',
          'item-type': 'section',
          'section-slug': 'entertainment',
          'tag-slug': null,
          id: 7611,
          'parent-id': 4079,
          url: 'https://ace-web.qtstage.io/news/entertainment',
          'entity-name': null,
          'created-at': 1574415880437,
          'section-name': 'Entertainment',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/entertainment'
        },
        {
          'updated-at': 1574415891325,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 18454,
          rank: 7612,
          title: 'Business Business Business BusinessBusiness Business',
          'item-type': 'section',
          'section-slug': 'elections-2018',
          'tag-slug': null,
          id: 7612,
          'parent-id': 4079,
          url: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018',
          'entity-name': null,
          'created-at': 1574415891325,
          'section-name': 'Elections 2018',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018'
        },
        {
          'updated-at': 1574415929506,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 18454,
          rank: 7615,
          title: 'Handpicked for me',
          'item-type': 'section',
          'section-slug': 'elections-2018',
          'tag-slug': null,
          id: 7615,
          'parent-id': 4079,
          url: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018',
          'entity-name': null,
          'created-at': 1574415929506,
          'section-name': 'Elections 2018',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018'
        },
        {
          'updated-at': 1574415942830,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 32459,
          rank: 7616,
          title: 'Bollywood Bollywood Bollywood Bollywood BollywoodBollywood',
          'item-type': 'section',
          'section-slug': 'criminal',
          'tag-slug': null,
          id: 7616,
          'parent-id': 4079,
          url: 'https://ace-web.qtstage.io/news/criminal',
          'entity-name': null,
          'created-at': 1574415942830,
          'section-name': 'Criminal',
          'menu-group-id': 43,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/criminal'
        }
      ],
      completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather'
    },
    {
      'updated-at': 1582614431953,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 6447,
      rank: 4080,
      title: 'Medium',
      'item-type': 'section',
      'section-slug': 'entertainment',
      'tag-slug': null,
      id: 8905,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/entertainment',
      'entity-name': null,
      'created-at': 1582614363127,
      'section-name': 'Entertainment',
      'menu-group-id': 43,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/entertainment'
    },
    {
      'updated-at': 1584006154486,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'sidebar-menu',
      'item-id': 6657,
      rank: 4081,
      title: 'Global News',
      'item-type': 'section',
      'section-slug': 'global-issues',
      'tag-slug': null,
      id: 4082,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/global-issues',
      'entity-name': null,
      'created-at': 1542803916512,
      'section-name': 'Global Issues',
      'menu-group-id': 45,
      data: { color: '#000000' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/global-issues'
    },
    {
      'updated-at': 1584006154487,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'sidebar-menu',
      'item-id': null,
      rank: 4082,
      title: 'Technology',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 4072,
      'parent-id': null,
      url: 'https://www.tgdaily.com/',
      'entity-name': null,
      'created-at': 1542802728754,
      'section-name': null,
      'menu-group-id': 45,
      data: { color: '#000000', link: 'https://www.tgdaily.com/' },
      children: [
        {
          'updated-at': 1564554965868,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'sidebar-menu',
          'item-id': 18454,
          rank: 3218,
          title: 'Automotive Menu ',
          'item-type': 'section',
          'section-slug': 'elections-2018',
          'tag-slug': null,
          id: 2439,
          'parent-id': 4072,
          url: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018',
          'entity-name': null,
          'created-at': 1533706920952,
          'section-name': 'Elections 2018',
          'menu-group-id': 45,
          data: { color: '#000000' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018'
        },
        {
          'updated-at': 1564563370663,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'sidebar-menu',
          'item-id': 19005,
          rank: 4073,
          title: 'Web',
          'item-type': 'section',
          'section-slug': 'empty-collection',
          'tag-slug': null,
          id: 4073,
          'parent-id': 4072,
          url: 'https://ace-web.qtstage.io/news/empty-collection',
          'entity-name': null,
          'created-at': 1542802766194,
          'section-name': 'Empty Collection',
          'menu-group-id': 45,
          data: { color: '#000000' },
          children: [
            {
              'updated-at': 1564555003459,
              'tag-name': null,
              'entity-properties': null,
              'entity-slug': null,
              'publisher-id': 97,
              'menu-group-slug': 'sidebar-menu',
              'item-id': 6449,
              rank: 4076,
              title: 'Software',
              'item-type': 'section',
              'section-slug': 'current-affairs',
              'tag-slug': null,
              id: 4076,
              'parent-id': 4073,
              url: 'https://ace-web.qtstage.io/current-affairs',
              'entity-name': null,
              'created-at': 1542803018746,
              'section-name': 'Current Affairs',
              'menu-group-id': 45,
              data: { color: '#000000' },
              children: [],
              completeUrl: 'https://ace-web.qtstage.io/current-affairs'
            }
          ],
          completeUrl: 'https://ace-web.qtstage.io/news/empty-collection'
        },
        {
          'updated-at': 1574420699023,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'sidebar-menu',
          'item-id': 19005,
          rank: 7618,
          title: 'Handpicked for me',
          'item-type': 'section',
          'section-slug': 'empty-collection',
          'tag-slug': null,
          id: 7618,
          'parent-id': 4072,
          url: 'https://ace-web.qtstage.io/news/empty-collection',
          'entity-name': null,
          'created-at': 1574420699023,
          'section-name': 'Empty Collection',
          'menu-group-id': 45,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/empty-collection'
        },
        {
          'updated-at': 1574420711415,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'sidebar-menu',
          'item-id': 6447,
          rank: 7619,
          title: 'Movies',
          'item-type': 'section',
          'section-slug': 'entertainment',
          'tag-slug': null,
          id: 7619,
          'parent-id': 4072,
          url: 'https://ace-web.qtstage.io/news/entertainment',
          'entity-name': null,
          'created-at': 1574420711415,
          'section-name': 'Entertainment',
          'menu-group-id': 45,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/entertainment'
        },
        {
          'updated-at': 1574420722342,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'sidebar-menu',
          'item-id': 32459,
          rank: 7620,
          title: 'Business Business Business BusinessBusiness Business',
          'item-type': 'section',
          'section-slug': 'criminal',
          'tag-slug': null,
          id: 7620,
          'parent-id': 4072,
          url: 'https://ace-web.qtstage.io/news/criminal',
          'entity-name': null,
          'created-at': 1574420722342,
          'section-name': 'Criminal',
          'menu-group-id': 45,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/criminal'
        },
        {
          'updated-at': 1574420733842,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'sidebar-menu',
          'item-id': 19005,
          rank: 7621,
          title: 'Bollywood Bollywood Bollywood Bollywood BollywoodBollywood',
          'item-type': 'section',
          'section-slug': 'empty-collection',
          'tag-slug': null,
          id: 7621,
          'parent-id': 4072,
          url: 'https://ace-web.qtstage.io/news/empty-collection',
          'entity-name': null,
          'created-at': 1574420733842,
          'section-name': 'Empty Collection',
          'menu-group-id': 45,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/empty-collection'
        }
      ],
      completeUrl: 'https://www.tgdaily.com/',
      isExternalLink: true
    },
    {
      'updated-at': 1582614431952,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 6450,
      rank: 4083,
      title: 'Regional',
      'item-type': 'section',
      'section-slug': 'regional',
      'tag-slug': null,
      id: 4078,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/regional',
      'entity-name': null,
      'created-at': 1542803338480,
      'section-name': 'Regional',
      'menu-group-id': 43,
      data: { color: '#000000' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/regional'
    },
    {
      'updated-at': 1548926316068,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': 5746,
      rank: 4086,
      title: 'Suppliers',
      'item-type': 'section',
      'section-slug': 'politics',
      'tag-slug': null,
      id: 4086,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/paro-bug-bash/politics',
      'entity-name': null,
      'created-at': 1542805068091,
      'section-name': 'Politics',
      'menu-group-id': 42,
      data: { color: '#000000' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/paro-bug-bash/politics'
    },
    {
      'updated-at': 1582614425038,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 23016,
      rank: 4087,
      title: 'Book Review',
      'item-type': 'section',
      'section-slug': 'testsection',
      'tag-slug': null,
      id: 4083,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/food-culture/testsection',
      'entity-name': null,
      'created-at': 1542804019603,
      'section-name': 'TestSection',
      'menu-group-id': 43,
      data: { color: '#000000' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/food-culture/testsection'
    },
    {
      'updated-at': 1552564289862,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': 5670,
      rank: 4101,
      title: 'World',
      'item-type': 'section',
      'section-slug': 'news',
      'tag-slug': null,
      id: 4087,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/recent-stories/news',
      'entity-name': null,
      'created-at': 1542805144108,
      'section-name': 'News',
      'menu-group-id': 42,
      data: { color: '#000000' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/recent-stories/news'
    },
    {
      'updated-at': 1552564283543,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': 6449,
      rank: 5165,
      title: 'Transport',
      'item-type': 'section',
      'section-slug': 'current-affairs',
      'tag-slug': null,
      id: 4595,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/current-affairs',
      'entity-name': null,
      'created-at': 1545297180936,
      'section-name': 'Current Affairs',
      'menu-group-id': 42,
      data: { color: '#000000' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/current-affairs'
    },
    {
      'updated-at': 1552564281066,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer',
      'item-id': null,
      rank: 5166,
      title: 'Privacy Policy',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 5165,
      'parent-id': null,
      url: '/privacy-policy',
      'entity-name': null,
      'created-at': 1549015968607,
      'section-name': null,
      'menu-group-id': null,
      data: { color: '#000000', link: '/privacy-policy' },
      children: [],
      completeUrl: '/privacy-policy',
      isExternalLink: true
    },
    {
      'updated-at': 1552564276746,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer',
      'item-id': null,
      rank: 5167,
      title: 'Contact Us',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 5166,
      'parent-id': null,
      url: '/contact-us',
      'entity-name': null,
      'created-at': 1549016032161,
      'section-name': null,
      'menu-group-id': null,
      data: { color: '#000000', link: '/contact-us' },
      children: [],
      completeUrl: '/contact-us',
      isExternalLink: true
    },
    {
      'updated-at': 1552564274624,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer',
      'item-id': null,
      rank: 5169,
      title: 'About Us',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 5167,
      'parent-id': null,
      url: '/about-us',
      'entity-name': null,
      'created-at': 1549016072811,
      'section-name': null,
      'menu-group-id': null,
      data: { color: '#000000', link: '/about-us' },
      children: [],
      completeUrl: '/about-us',
      isExternalLink: true
    },
    {
      'updated-at': 1580452283407,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'header',
      'item-id': 5748,
      rank: 5746,
      title: 'Roman',
      'item-type': 'section',
      'section-slug': 'sports',
      'tag-slug': null,
      id: 3406,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/sports',
      'entity-name': null,
      'created-at': 1538040329674,
      'section-name': 'Sports',
      'menu-group-id': 44,
      data: { color: '#000000', link: '/' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/sports'
    },
    {
      'updated-at': 1582614420093,
      'tag-name': 't100',
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 197961,
      rank: 6552,
      title: 'Opinion',
      'item-type': 'tag',
      'section-slug': null,
      'tag-slug': 't100',
      id: 4080,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/topic/t100',
      'entity-name': null,
      'created-at': 1542803488800,
      'section-name': null,
      'menu-group-id': 43,
      data: { color: '#000000' },
      children: [],
      completeUrl: '/topic/t100'
    },
    {
      'updated-at': 1582614414224,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'header',
      'item-id': null,
      rank: 7124,
      title: 'Testing sidebar Menu',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 5746,
      'parent-id': null,
      url: 'Google.com',
      'entity-name': null,
      'created-at': 1552558297664,
      'section-name': null,
      'menu-group-id': 43,
      data: { color: '#000000', link: 'Google.com' },
      children: [],
      completeUrl: 'Google.com',
      isExternalLink: true
    },
    {
      'updated-at': 1571387396199,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer',
      'item-id': 6447,
      rank: 7200,
      title: 'Movies',
      'item-type': 'section',
      'section-slug': 'entertainment',
      'tag-slug': null,
      id: 7334,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/entertainment',
      'entity-name': null,
      'created-at': 1571030694439,
      'section-name': 'Entertainment',
      'menu-group-id': 46,
      data: { color: '#FFFFFF' },
      children: [
        {
          'updated-at': 1571048053649,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'footer',
          'item-id': 6447,
          rank: 7363,
          title: 'Bollywood Bollywood Bollywood Bollywood BollywoodBollywood',
          'item-type': 'section',
          'section-slug': 'entertainment',
          'tag-slug': null,
          id: 7363,
          'parent-id': 7334,
          url: 'https://ace-web.qtstage.io/news/entertainment',
          'entity-name': null,
          'created-at': 1571048053649,
          'section-name': 'Entertainment',
          'menu-group-id': 46,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/entertainment'
        }
      ],
      completeUrl: 'https://ace-web.qtstage.io/news/entertainment'
    },
    {
      'updated-at': 1571387396203,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer',
      'item-id': null,
      rank: 7334,
      title: 'Test AMJ',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 7200,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/test-amj',
      'entity-name': null,
      'created-at': 1567587486300,
      'section-name': null,
      'menu-group-id': 46,
      data: { color: '#FFFFFF', link: 'https://ace-web.qtstage.io/test-amj' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/test-amj',
      isExternalLink: true
    },
    {
      'updated-at': 1571030786010,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer',
      'item-id': 6448,
      rank: 7335,
      title: 'Handpicked for me',
      'item-type': 'section',
      'section-slug': 'food-culture',
      'tag-slug': null,
      id: 7335,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/food-culture',
      'entity-name': null,
      'created-at': 1571030786010,
      'section-name': 'Food culture',
      'menu-group-id': 46,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/food-culture'
    },
    {
      'updated-at': 1573194481203,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer-site-links',
      'item-id': 12025,
      rank: 7499,
      title: 'Bollywood',
      'item-type': 'section',
      'section-slug': 'firstsection',
      'tag-slug': null,
      id: 7499,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/firstsection',
      'entity-name': null,
      'created-at': 1573194481203,
      'section-name': 'Firstsection',
      'menu-group-id': 1207,
      data: { color: '#FFFFFF' },
      children: [
        {
          'updated-at': 1573194840165,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'footer-site-links',
          'item-id': 32458,
          rank: 7505,
          title: 'Movies 2019',
          'item-type': 'section',
          'section-slug': 'crime',
          'tag-slug': null,
          id: 7505,
          'parent-id': 7499,
          url: 'https://ace-web.qtstage.io/news/crime',
          'entity-name': null,
          'created-at': 1573194840165,
          'section-name': 'Crimes',
          'menu-group-id': 1207,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/crime'
        },
        {
          'updated-at': 1573194873136,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'footer-site-links',
          'item-id': 6447,
          rank: 7506,
          title: 'Movies 2020',
          'item-type': 'section',
          'section-slug': 'entertainment',
          'tag-slug': null,
          id: 7506,
          'parent-id': 7499,
          url: 'https://ace-web.qtstage.io/news/entertainment',
          'entity-name': null,
          'created-at': 1573194873136,
          'section-name': 'Entertainment',
          'menu-group-id': 1207,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/entertainment'
        },
        {
          'updated-at': 1573194916297,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'footer-site-links',
          'item-id': 18454,
          rank: 7507,
          title: 'Movies 2018',
          'item-type': 'section',
          'section-slug': 'elections-2018',
          'tag-slug': null,
          id: 7507,
          'parent-id': 7499,
          url: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018',
          'entity-name': null,
          'created-at': 1573194916297,
          'section-name': 'Elections 2018',
          'menu-group-id': 1207,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather/elections-2018'
        },
        {
          'updated-at': 1573195017219,
          'tag-name': 'Alternate',
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'footer-site-links',
          'item-id': 187103,
          rank: 7508,
          title: 'Films',
          'item-type': 'tag',
          'section-slug': null,
          'tag-slug': 'alternate',
          id: 7508,
          'parent-id': 7499,
          url: 'https://ace-web.qtstage.io/topic/alternate',
          'entity-name': null,
          'created-at': 1573195017219,
          'section-name': null,
          'menu-group-id': 1207,
          data: { color: '#FFFFFF' },
          children: [],
          completeUrl: '/topic/alternate'
        },
        {
          'updated-at': 1573195059190,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'footer-site-links',
          'item-id': null,
          rank: 7509,
          title: 'Bollywood Humgama',
          'item-type': 'link',
          'section-slug': null,
          'tag-slug': null,
          id: 7509,
          'parent-id': 7499,
          url: 'https://facebook.com',
          'entity-name': null,
          'created-at': 1573195046982,
          'section-name': null,
          'menu-group-id': 1207,
          data: { color: '#FFFFFF', link: 'https://facebook.com' },
          children: [],
          completeUrl: 'https://facebook.com',
          isExternalLink: true
        }
      ],
      completeUrl: 'https://ace-web.qtstage.io/news/firstsection'
    },
    {
      'updated-at': 1573194492332,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer-site-links',
      'item-id': 32458,
      rank: 7500,
      title: 'Foodies',
      'item-type': 'section',
      'section-slug': 'crime',
      'tag-slug': null,
      id: 7500,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/crime',
      'entity-name': null,
      'created-at': 1573194492332,
      'section-name': 'Crimes',
      'menu-group-id': 1207,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/crime'
    },
    {
      'updated-at': 1573194662358,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer-site-links',
      'item-id': 6447,
      rank: 7501,
      title: 'Movies',
      'item-type': 'section',
      'section-slug': 'entertainment',
      'tag-slug': null,
      id: 7501,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/entertainment',
      'entity-name': null,
      'created-at': 1573194662358,
      'section-name': 'Entertainment',
      'menu-group-id': 1207,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/entertainment'
    },
    {
      'updated-at': 1573194683030,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer-site-links',
      'item-id': 32459,
      rank: 7502,
      title: 'Sports',
      'item-type': 'section',
      'section-slug': 'criminal',
      'tag-slug': null,
      id: 7502,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/criminal',
      'entity-name': null,
      'created-at': 1573194683030,
      'section-name': 'Criminal',
      'menu-group-id': 1207,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/criminal'
    },
    {
      'updated-at': 1573194698234,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer-site-links',
      'item-id': 19005,
      rank: 7503,
      title: 'Politics',
      'item-type': 'section',
      'section-slug': 'empty-collection',
      'tag-slug': null,
      id: 7503,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/empty-collection',
      'entity-name': null,
      'created-at': 1573194698234,
      'section-name': 'Empty Collection',
      'menu-group-id': 1207,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/empty-collection'
    },
    {
      'updated-at': 1573194803658,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'footer-site-links',
      'item-id': 32459,
      rank: 7504,
      title: 'Travel',
      'item-type': 'section',
      'section-slug': 'criminal',
      'tag-slug': null,
      id: 7504,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/criminal',
      'entity-name': null,
      'created-at': 1573194803658,
      'section-name': 'Criminal',
      'menu-group-id': 1207,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/criminal'
    },
    {
      'updated-at': 1582614404637,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': null,
      rank: 8130,
      title: 'Test',
      'item-type': 'placeholder',
      'section-slug': null,
      'tag-slug': null,
      id: 6552,
      'parent-id': null,
      url: null,
      'entity-name': null,
      'created-at': 1557309038083,
      'section-name': null,
      'menu-group-id': 43,
      data: { color: '#000000' },
      children: [
        {
          'updated-at': 1557309285086,
          'tag-name': null,
          'entity-properties': null,
          'entity-slug': null,
          'publisher-id': 97,
          'menu-group-slug': 'default',
          'item-id': 6449,
          rank: 6553,
          title: 'Test-child 1',
          'item-type': 'section',
          'section-slug': 'current-affairs',
          'tag-slug': null,
          id: 6553,
          'parent-id': 6552,
          url: 'https://ace-web.qtstage.io/current-affairs',
          'entity-name': null,
          'created-at': 1557309275053,
          'section-name': 'Current Affairs',
          'menu-group-id': 43,
          data: { color: '#000000' },
          children: [],
          completeUrl: 'https://ace-web.qtstage.io/current-affairs'
        }
      ],
      completeUrl: '/#'
    },
    {
      'updated-at': 1582614404433,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': null,
      rank: 8140,
      title: 'Test Link',
      'item-type': 'link',
      'section-slug': null,
      'tag-slug': null,
      id: 7124,
      'parent-id': null,
      url: 'https://facebook.com',
      'entity-name': null,
      'created-at': 1565244066025,
      'section-name': null,
      'menu-group-id': 43,
      data: { color: '#FFFFFF', link: 'https://facebook.com' },
      children: [],
      completeUrl: 'https://facebook.com',
      isExternalLink: true
    },
    {
      'updated-at': 1584006853041,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 6447,
      rank: 8905,
      title: 'High',
      'item-type': 'section',
      'section-slug': 'entertainment',
      'tag-slug': null,
      id: 8912,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/entertainment',
      'entity-name': null,
      'created-at': 1582780160602,
      'section-name': 'Entertainment',
      'menu-group-id': 43,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/entertainment'
    },
    {
      'updated-at': 1584006853040,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 38588,
      rank: 8912,
      title: 'Testing Section',
      'item-type': 'section',
      'section-slug': 'test',
      'tag-slug': null,
      id: 8140,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/test',
      'entity-name': null,
      'created-at': 1578474717235,
      'section-name': 'Test',
      'menu-group-id': 43,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/test'
    },
    {
      'updated-at': 1584006845515,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 12102,
      rank: 8913,
      title: 'Budget',
      'item-type': 'section',
      'section-slug': 'newsection',
      'tag-slug': null,
      id: 8913,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/newsection',
      'entity-name': null,
      'created-at': 1582780187835,
      'section-name': 'Newsection',
      'menu-group-id': 43,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/newsection'
    },
    {
      'updated-at': 1584006845514,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'default',
      'item-id': 6452,
      rank: 8914,
      title: 'Travel',
      'item-type': 'section',
      'section-slug': 'weather',
      'tag-slug': null,
      id: 8914,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/lifestyle/weather',
      'entity-name': null,
      'created-at': 1582780204612,
      'section-name': 'Weather',
      'menu-group-id': 43,
      data: { color: '#FFFFFF' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/lifestyle/weather'
    },
    {
      'updated-at': 1584005749539,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': 32458,
      rank: 8975,
      title: 'Criminal',
      'item-type': 'section',
      'section-slug': 'crime',
      'tag-slug': null,
      id: 8975,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/crime',
      'entity-name': null,
      'created-at': 1583992813504,
      'section-name': 'Crimes',
      'menu-group-id': 42,
      data: { color: '#000000' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/crime'
    },
    {
      'updated-at': 1584005765618,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': 32459,
      rank: 8976,
      title: 'crime',
      'item-type': 'section',
      'section-slug': 'criminal',
      'tag-slug': null,
      id: 8976,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/criminal',
      'entity-name': null,
      'created-at': 1583992869025,
      'section-name': 'Criminal',
      'menu-group-id': 42,
      data: { color: '#000000' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/criminal'
    },
    {
      'updated-at': 1584005728908,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': 6447,
      rank: 8977,
      title: 'Entertaintment',
      'item-type': 'section',
      'section-slug': 'entertainment',
      'tag-slug': null,
      id: 8977,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/entertainment',
      'entity-name': null,
      'created-at': 1583992991884,
      'section-name': 'Entertainment',
      'menu-group-id': 42,
      data: { color: '#000000' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/entertainment'
    },
    {
      'updated-at': 1584005698901,
      'tag-name': null,
      'entity-properties': null,
      'entity-slug': null,
      'publisher-id': 97,
      'menu-group-slug': 'secondary-menu',
      'item-id': 11181,
      rank: 8978,
      title: 'Health',
      'item-type': 'section',
      'section-slug': 'health',
      'tag-slug': null,
      id: 8978,
      'parent-id': null,
      url: 'https://ace-web.qtstage.io/news/health',
      'entity-name': null,
      'created-at': 1583993116677,
      'section-name': 'Health',
      'menu-group-id': 42,
      data: { color: '#060606' },
      children: [],
      completeUrl: 'https://ace-web.qtstage.io/news/health'
    }
  ]
};
