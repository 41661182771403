import axios from 'axios';
import get from 'lodash/get';
import { defaultStoryTypes } from '../containers/story/story-page';
import { IAuthData, IComponentMeta, IConfig, ILanguage, ISelectedRow, IStoryTemplates } from './interfaces';

interface FontsData {
  label: string;
  [value: string]: string;
}

interface Domain {
  'beta-host-url'?: any;
  'home-collection-id': number;
  'host-url': string;
  language: ILanguage;
  'menu-groups': any[];
  name: string;
  'section-ids': number[];
  slug: string;
}

interface BoldConfig {
  [key: string]: any; // variable keys
  domains: Domain[];
  slug: string;
  language: ILanguage;
}

export const Compare = (fontA: FontsData, fontB: FontsData) => {
  const valueA = fontA.value.toUpperCase();
  const valueB = fontB.value.toUpperCase();
  if (valueA < valueB) return -1;
  if (valueA > valueB) return 1;
  return 0;
};

export const authenticateApi = async (data: IAuthData) => {
  try {
    const response = await fetch('/login', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.error(err);
    alert('Error logging in please try again');
  }
};

export function addFontLinkTag(fontsName: string): Promise<any> {
  const fontVariants = ['regular', 'semi-bold', 'bold'];
  if (!fontsName.length) return new Promise(resolve => resolve());

  const getFontFamilyName = () => {
    return `${fontsName.replace(/ /g, '+')}:${fontVariants.join(',')}`;
  };
  const getFontFileName = () => {
    return fontsName.replace(/ /g, '').toLowerCase();
  };
  const uri = fontsName.includes('noto')
    ? `https://fonts.googleapis.com/earlyaccess/${getFontFileName()}.css`
    : `https://fonts.googleapis.com/css?family=${getFontFamilyName()}&display=swap`;

  return new Promise((resolve, reject) => {
    if (document.getElementById(getFontFileName())) return resolve();
    const link = document.createElement('link');
    link.id = getFontFileName();
    link.rel = 'stylesheet';
    link.href = uri;
    link.onload = resolve;
    link.onerror = reject;
    document.head.appendChild(link);
  });
}

export const checkValidEncodedCustomCode = (encodedCustomCode: string) => {
  try {
    return window.decodeURI(window.atob(encodedCustomCode));
  } catch (e) {
    return '';
  }
};

export const encodeCustomCode = (customCode: string) => {
  return window.btoa(window.encodeURI(customCode));
};

export const getSavedConfig = async (publisherId: string, domain: string) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/${publisherId}/${domain}/save`);
    return get(response, ['data'], {});
  } catch (error) {
    console.log('Error while fetching saved config:', error);
  }
};

export const saveConfig = async (publisherId: string, config: object, domain: string) => {
  try {
    return fetch(`${BASE_API_URL}/${publisherId}/${domain}/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ config })
    }).then(response => response.json());
  } catch (error) {
    console.log('Error while saving config version:', error);
  }
};

export const changeConfigVersion = (publisherId: string, domain: string, action: string) => {
  return fetch(`${BASE_API_URL}/${publisherId}/${domain}/save`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ action })
  }).then(res => res.json());
};

export const getUserDetails = (publisherId: string) => {
  return fetch(`${BASE_API_URL}/${publisherId}/user`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => res.json());
};

export const publishChanges = async (publisherId: string, config: IConfig, domain: string) => {
  const configWithLightRowStatus = await getConfigWithLightRowStatus(config);
  const updatedConfig = { ...configWithLightRowStatus, configVersion: new Date().getTime() };
  try {
    return await (
      await fetch(`${BASE_API_URL}/${publisherId}/${domain}/config`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ config: updatedConfig })
      })
    ).json();
  } catch (err) {
    console.log('Error while publishing changes.', err);
  }
};

export const updateCurrentEditor = async (publisherId: string, email: string | null) => {
  try {
    const response = await fetch(`${BASE_API_URL}/${publisherId}/editor`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    });
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

export const getCurrentEditor = async (publisherId: string) => {
  try {
    const response = await fetch(`${BASE_API_URL}/${publisherId}/editor`);
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

export const updateBodyScroll = (removeScrollSelector: boolean = false, selectorName: string = '') => {
  const element = document.getElementsByTagName('body')[0];
  return removeScrollSelector ? element.classList.remove(selectorName) : element.classList.add(selectorName);
};

export const getScript = (src: string, appendTo: any, onload: () => void = () => {}) => {
  const script = document.createElement('script');
  script.type = 'application/javascript';
  script.async = true;
  script.src = src;
  script.onload = onload;
  appendTo.appendChild(script);
};

export const getStylesheet = (href: string) => {
  const style = document.createElement('link');
  style.type = 'text/css';
  style.rel = 'stylesheet';
  style.media = 'all';
  style.href = href;
  return style;
};

export const generateComponentList = ({ pageType, layoutsList = [] }: IComponentMeta) => {
  const layoutView = layoutsList.filter((type: any) => type.viewType !== 'default');
  const supportedTagPageLayouts = [
    'ArrowThreeColGrid',
    'ArrowOneColStoryList',
    'ArrowFourColFiveStories',
    'ArrowSixColSixStories'
  ];
  const tagPageLayouts = layoutsList.filter((item: any) => supportedTagPageLayouts.includes(item.componentName));
  switch (pageType) {
    case 'tag':
      return tagPageLayouts;
    case 'author':
      return layoutView;
    default:
      return layoutsList;
  }
};

export const generateComponentLabel = ({ pageType, viewType, componentLabel }: IComponentMeta) => {
  switch (pageType) {
    case 'author':
      return viewType;
    default:
      return componentLabel;
  }
};

export const updateActionOption = (action: string, type: string) => {
  const adWidgetDropdownOptions = [
    { label: 'Ads', value: 'Ads' },
    { label: 'Widget', value: 'widget' }
  ];
  const storiesDropdownOption = [{ label: 'Stories', value: 'stories' }];
  switch (action) {
    case 'add_row_top':
      return adWidgetDropdownOptions;
    case 'add_row_bottom':
      return adWidgetDropdownOptions;
    case 'update':
      return type === 'Collections' ? storiesDropdownOption : adWidgetDropdownOptions;
    default:
      return [];
  }
};

export const getDefaultValue = (type: string) => {
  switch (type) {
    case 'Ads ':
      return { label: 'Ads', value: 'Ads' };
    case 'widget':
      return { label: 'Widget', value: 'widget' };
    case 'Collections':
      return { label: 'Stories', value: 'stories' };
    default:
      return null;
  }
};
// Function to check whether we are on a subdomain or not, check whether it is localhost for dev
export const isSubdomain = () => {
  const hostname = window.location.hostname;
  if (hostname.startsWith('localhost') || hostname.startsWith('pagebuilder')) return false;
  return true;
};

export const splitCamelCase = (text: string) => {
  return text && text.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');
};

const getConfigWithLightRowStatus = async (config: IConfig) => {
  const heavyRowList = await getHeavyRowList();
  const homeRows = get(config, ['home', 'rows'], []);
  const isEveryRowLight = !homeRows.find((item: ISelectedRow) => heavyRowList.includes(item.layout));
  return Object.assign({}, config, { home: { ...config.home, lightRows: isEveryRowLight } });
};

const getHeavyRowList = async () => {
  const response = await fetch('/ahead/components-metadata');
  const componentMetaData = await response.json();

  return componentMetaData
    .filter((item: IComponentMeta) => item.lightRow !== undefined && !item.lightRow)
    .map((item: IComponentMeta) => item.componentName);
};

export const getRouteData = async (path: string) => {
  const url = `/route-data.json?path=${path}`;
  const routeDataResponse = await fetch(url);
  return routeDataResponse.json();
};

export const redirectToSSOLogin = async (
  integrationId: number,
  redirectUri: string,
  errorCallback: any,
  navigateCallback: any
) => {
  const params = `client_id=${integrationId}&redirect_uri=${redirectUri}&response_type=code&allow_ajax=true`;
  const url = `/api/auth/v1/oauth/authorize?${params}`;
  const res = await fetch(url, {
    method: 'GET'
  });
  if (res) {
    if (res.status === 200) {
      const response = await res.json();
      navigateCallback ? navigateCallback(response.redirect_uri) : (window.location.href = response.redirect_uri);
    } else {
      const response = await res.json();
      errorCallback ? navigateCallback(response.error_description) : alert(response.error_description);
    }
  }
};

export const addUserToPublisher = async (userEmail: string, publisherId: string, role: string) => {
  const payload = { userEmail, role };
  const userCreationResponseData = await axios.post(`/api/v1/accounts/${publisherId}/user`, payload);
  return get(userCreationResponseData, ['data']);
};

export const getBoldConfig = async (publisherId: string) => {
  try {
    const response = await axios.get(`/api/v1/publisher/${publisherId}/bold-config`);
    return get(response, ['data'], {});
  } catch (error) {
    console.log('Could not fetch bold config:', error);
  }
};

export const getStoryTemplates = async (publisherId: string) => {
  const response = await (await fetch(`/api/v1/publisher/${publisherId}/internal-apps/bold-config`)).json();
  const storyTemplates = get(response, ['story-templates'], []);
  return storyTemplates.filter((template: IStoryTemplates) => template.toggle);
};

export const getStoryName = (name: string = '') => {
  if (name.endsWith('-story')) {
    return name.slice(0, -6);
  }
  return name;
};

export const getStoryStoreField = (storyName: string) => {
  // Check if story is default story type
  if (defaultStoryTypes.includes(storyName)) {
    return 'story';
  }
  return 'customStory';
};

export const setupSubdomain = async (publisherId: string, subdomain: string) => {
  const body = {
    name: subdomain
  };
  return axios.put(`${BASE_API_URL}/${publisherId}/setup-subdomain`, body);
};

export const getConfiguredDomains = async (publisherId: string) => {
  return axios.get(`${BASE_API_URL}/${publisherId}/domains`);
};

export const getPublishedConfig = async (publisherId: string, domain: string) => {
  return axios.get(`${BASE_API_URL}/${publisherId}/${domain}/config`);
};

export const BASE_API_URL = '/api/v1/accounts';

export const builderConfigKeysToPageType: { [key: string]: string } = {
  general: 'all',
  header: 'all',
  breakingNews: 'all',
  footer: 'all',
  home: 'home',
  story: 'story',
  author: 'author',
  authors: 'author',
  tag: 'tag',
  search: 'search',
  magazine: 'magazine',
  'magazine-landing': 'magazine',
  'issue-landing': 'magazine-issue',
  archive: 'magazine-archive',
  customStory: 'story',
  infiniteScroll: 'story',
  section: 'section',
  landingPage: 'section',
  subscription: 'subscription',
  meteredPaywall: 'meteredPaywall'
};

export const updatePageType = (updatedKey: string, currentPageTypes: Array<string>) => {
  const pageType = builderConfigKeysToPageType[updatedKey];
  return currentPageTypes.includes(pageType) ? currentPageTypes : [...currentPageTypes, pageType];
};

export function setPageDirection(direction: string = 'ltr') {
  const html = document.getElementsByTagName('html')[0];
  html.setAttribute('dir', direction);
  if (direction.toLowerCase() === 'rtl') html.classList.add('pb__global__html--rtl');
  if (direction.toLowerCase() === 'ltr') html.classList.remove('pb__global__html--rtl');
}

export function getDomainLangauge(boldConfig: BoldConfig, domain: string): ILanguage {
  const defaultLanguage: ILanguage = {
    direction: 'ltr'
  };
  const domains: Domain[] = boldConfig.domains;
  const domainConfig = domains.filter(doomain => doomain.slug === domain)[0];
  if (domainConfig) {
    return domainConfig.language || defaultLanguage;
  }
  return boldConfig.language || defaultLanguage;
}

export function getPageType() {
  const url = window.location.pathname;
  const params = url.split('/');
  const pageType = params?.[params.length - 1];
  return pageType;
}
