import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { IPBState } from '../../utils/interfaces';
import { RowTitleStyle } from '../row-title';
import styles from './styling.module.css';
import { PhotoStoryDemarcation } from '../story-demarcation/photo-story-demarcation';
import { VideoStoryDemarcation } from '../story-demarcation/video-story-demarcation';
import { VisualStoryDemarcation } from '../story-demarcation/visual-story-demarcation';
import { storyDemarcationStyle } from '../../actions/builder-action';
import { ThemeSetter } from '../theme-setter';
import { SectionTag } from '../section-tag';
import { PremiumStoryIcons } from '../premium-story-icons';
import { defaultDarkTheme } from '../../constant';
import { Separator } from '../../atoms/separator';

const Styling: React.FunctionComponent = () => {
  const [isAccordionActive, updateAccordionActive] = useState(false);

  const onClickHandler = () => {
    updateAccordionActive(!isAccordionActive);
  };

  const { enableDarkModePreview = false } = useSelector((state: IPBState) => get(state, ['builder', 'config']));

  const { iconColor = '', darkIconColor = '' } = useSelector(
    (state: IPBState) => get(state, ['builder', 'config', 'general', 'rows']) || {}
  );

  const dispatch = useDispatch();
  const updateIconColor = (key: string, value: string) => dispatch(storyDemarcationStyle({ [key]: value }));

  return (
    <div className={styles.stylingWrapper}>
      <Accordion hasSingleActivePanel onToggle={onClickHandler}>
        <AccordionSection label="Row Title Design" isLabelUpperCase index={0}>
          <RowTitleStyle />
        </AccordionSection>
        <AccordionSection label="Section Tag" isLabelUpperCase index={1}>
          <SectionTag />
        </AccordionSection>
        <AccordionSection label="Story Demarcation" isLabelUpperCase index={2}>
          <div className={styles['demarcation-container']}>
            <PhotoStoryDemarcation />
          </div>
          <div className={styles['demarcation-container']}>
            <VideoStoryDemarcation />
          </div>
          <div className={styles['demarcation-container']}>
            <VisualStoryDemarcation />
          </div>
          <ThemeSetter
            label="Choose Icon color"
            updateTheStore={updateIconColor}
            lightColorExtract={iconColor}
            lightUpdateStoreKey="iconColor"
            darkColorExtract={darkIconColor}
            darkUpdateStoreKey="darkIconColor"
            darkDefaultColor={defaultDarkTheme}
            lightDefaultColor={defaultDarkTheme}
            enableDarkModePreview={enableDarkModePreview}
          />
          <Separator />
          <PremiumStoryIcons />
        </AccordionSection>
      </Accordion>
    </div>
  );
};

export default Styling;
